import { React, useEffect, useState } from 'react'
import { Button, Box, TextField, Tooltip, Grid, FormControl, Container, Typography, Modal} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { newCallAtom } from '../../../_state';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMasterActions, useNewCallActions } from '../../../_actions';
import { masterAtom } from '../../../_state';
import Header from '../Components/Header';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
const Modalstyle = {
    position: 'absolute',
    top: '50% !important',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 6,
};
const infoIc = process.env.PUBLIC_URL + "/images/customer/info_ic.svg"
const ShopNow = () => {
    const validationSchema = Yup.object().shape({        
        monthlyUsage: Yup.number()
        .positive("Must be more than 1 value")
        .min(1, "Must be more than 1 value")
        .max(84999, "Matrix pricing not available for this Monthly Usage"),          
    })
    //yup validation
    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build form with useForm() hook
    const { handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;    
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const masterApiAction = useMasterActions();
    const newCallActions = useNewCallActions();
    const { masterSetting ,loadFactor} = useRecoilValue(masterAtom);
    const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
    useEffect(() => {
        masterApiAction.getMasterData();     
        reset({...newCallFormData})  
    }, [])

    useEffect(() => {
        const searchIndex = loadFactor.findIndex((item) => item.name=="Low");
        setNewCall({ 
            ...newCallFormData, 
            margin: (masterSetting?.marginTerms==='no')?masterSetting.margin : 0,
            loadFactorId:loadFactor[searchIndex]?.id,
            requestedStartDate:(newCallFormData.serviceState==='TX')?dayjs().format('MM/DD/YYYY'):newCallFormData.requestedStartDate
         })
    }, [masterSetting])

    useEffect(() => {
        if (newCallFormData?.monthlyUsage > 84999) {
            setModalOpen(true)
        }
    }, [newCallFormData?.monthlyUsage])

    const onSubmit = ()=>{
        newCallActions.createNewCall();
        navigate('/get-rates/')
    }

    console.log(newCallFormData,'-step2')
    return (
        <>
            <div className="shop-now">
                <Container maxWidth="xl">
                    <Header />
                </Container>
                <Container maxWidth='md'>                    
                    {newCallFormData.enrollmentType !== 'Priority Move-In' &&
                        <Grid container spacing={2} >
                            {newCallFormData.enrollmentType === 'Move In' ?
                                <Grid item xs={12} sm={6}>
                                    <h3 className='text-cetner' style={{ display: 'flex', alignItems: 'flex-end', gap: '5px' }}>Estimated Square Footage <span style={{ lineHeight: '1' }}><Tooltip title={<h3>We'll use this to estimate how much electiricy you'll use</h3>} placement="top" arrow><img alt="infoIc" src={infoIc} /></Tooltip></span></h3>
                                    <FormControl fullWidth sx={{ bgcolor: '#fff', textAlign: 'center' }} >
                                        <TextField 
                                        type={'number'} 
                                        required 
                                        size="small"
                                        value={newCallFormData?.monthlyUsage || ''} 
                                        onChange={(e) =>{ reset({
                                            ...newCallFormData,
                                            monthlyUsage: e.target.value,
                                            annualUsage: (e.target.value * 0.012).toFixed(2),
                                            }); 
                                            setNewCall({ 
                                                ...newCallFormData, 
                                                monthlyUsage: e.target.value, 
                                                annualUsage: (e.target.value * 0.012).toFixed(2),
                                                monthlyLabel: 'Estimated Square Footage'                                                
                                             })}} />
                                    </FormControl>
                                    <div  className="invalid-feedback" style={{ color: "red" }}>{errors.monthlyUsage?.message}</div>

                                </Grid>
                                :
                                <Grid item xs={12} sm={6} >
                                    <h3 style={{ display: 'flex', alignItems: 'flex-end', gap: '5px' }}>Average Monthly Usage (kWh) <span style={{ lineHeight: '1', size: 16 }}><Tooltip title={<h3>Look in the supply section of your last bill</h3>} placement="top" arrow><img alt="infoIc" src={infoIc} /></Tooltip></span></h3>
                                    <FormControl  fullWidth sx={{ bgcolor: '#fff', textAlign: 'center' }} >
                                        <TextField 
                                        type={'number'} 
                                        required 
                                        size="small" 
                                        value={newCallFormData?.monthlyUsage || ''} 
                                        onChange={(e) =>{ 
                                            reset({...newCallFormData,monthlyUsage: e.target.value,annualUsage: (e.target.value * 0.012).toFixed(2),}); 
                                            setNewCall({ 
                                                ...newCallFormData,
                                                monthlyUsage: e.target.value,
                                                annualUsage: (e.target.value * 0.012).toFixed(2),
                                                monthlyLabel: 'Average Monthly Usage (kWh) ' 
                                                })
                                            }} />
                                    </FormControl>
                                    <div  className="invalid-feedback" style={{ color: "red" }}>{errors.monthlyUsage?.message}</div>

                                </Grid>
                            }

                            <Grid item xs={12} sm={6}>
                                <h3>Start Date</h3>
                                <FormControl fullWidth sx={{ bgcolor: '#fff' }} size="small" className='not-btn-style'>

                                    {newCallFormData.enrollmentType === 'Move In' ?
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                size="small"
                                                inputFormat="MM/DD/YYYY"                                                
                                                value={newCallFormData?.requestedStartDate || ''}
                                                onChange={(e) => setNewCall({
                                                    ...newCallFormData,
                                                    'requestedStartDate': e
                                                })}
                                                renderInput={(params) =>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        size='small'
                                                        type='date'
                                                        fullWidth
                                                        required
                                                        {...params}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                        :
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                size="small"
                                                inputFormat="MM/DD/YYYY"
                                                minDate={dayjs()}
                                                value={newCallFormData?.requestedStartDate || dayjs()}
                                                onChange={(e) => setNewCall({
                                                    ...newCallFormData,
                                                    'requestedStartDate': e
                                                })}
                                                renderInput={(params) =>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        size='small'
                                                        type='date'
                                                        fullWidth
                                                        required
                                                        {...params}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                    }

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Button sx={{ mt: 3 }} variant="contained" color='primary' size='small' className='btn-primary' onClick={handleSubmit(onSubmit)} >Continue to plans</Button>
                            </Grid>
                        </Grid>
                    }
                    {newCallFormData.enrollmentType === 'Priority Move-In' &&
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={12} >
                                <Typography
                                    component="p"
                                    variant="p"
                                    align="center"
                                    color="text.primary"
                                    sx={{
                                        mb: 3,
                                        mt: 5
                                    }}
                                >
                                    If you need power ASAP, the path of least resistance is a Month-to-Month contract with Green Mountain. This contract is credit pre-approved and is a standard fixed rate for 1 month. A Priority Move-In could contain an additional fee from the utility. Confirm with your local municipality inspections department if any certificates of occupancy are required
                                </Typography>
                            </Grid>

                            <Grid item xs={12} align="center">
                                <Button sx={{ m: 1, }} variant="outlined" color='primary' size='small' className='btn-outline-primary' onClick={() => { navigate(`/small-commercial-electricity-zip-code-lookup/`) }}>Go Back to shop different suppliers</Button>
                                <Button sx={{ m: 1, }} variant="contained" color='primary' size='small' className='btn-primary' onClick={() => { navigate(`/small-commercial-electricity-zip-code-lookup/`) }}>Continue with this priority move-in</Button>
                            </Grid>
                        </Grid>
                    }
                </Container>
                <Modal
                    open={modalOpen}
                    onClose={e => setModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={{ ...Modalstyle }} className='faq_modal'>
                        <Button variant='contained' color='primary' className='modal-close btn-primary' onClick={e => setModalOpen(false)}>
                            <CloseIcon />
                        </Button>
                        <Typography variant="h4" component="h3" align="center" sx={{ mb: 3, fontWeight: '600', fontSize: { sm: '32px', xs: '24px' } }}>
                            The kWh you entered is too high for online pricing.
                        </Typography>
                        <Typography variant="body1" component="p" align="center" sx={{ mb: 3, fontWeight: '500' }}>
                            To ensure that you get the best possible plan, a custom plan will need to be creared as your kWh usage is currently very high.
                        </Typography>
                        <Box
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <div><Button variant='outlined' color='primary' className='btn-outline-primary' sx={{ mb: 2 }} onClick={e => setModalOpen(false)} >Re-enter my kWh</Button></div>
                            <div><Button variant='contained' color='primary' className='btn-primary' onClick={() => { navigate(`/large-commercial-electricity`) }}>Continue to custom pricing</Button></div>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>
    )
}
export default ShopNow
