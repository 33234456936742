import { React, useEffect, useState } from 'react'
import { Button, Grid, Container, Modal, Box, FormControl, Autocomplete, TextField, ThemeProvider } from '@mui/material';
import { useLocation } from "react-router-dom";
import { useNewCallActions, useMasterActions, useLocation as useLocationAction } from '../../../_actions';
import { newCallAtom, masterAtom } from '../../../_state';
import { useRecoilState, useRecoilValue } from 'recoil';
import energyBy5Theme from '../../../muiColorTheme.js';
import Loading from '../Components/Loading';
import '../custom.css';

//open , method to update modal state
const UtilityModal = () => {
  const newCallActions = useNewCallActions();
  const masterAction = useMasterActions();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
  const { utility } = useRecoilValue(masterAtom);
  const [openUtilityModal, setOpenUtilityModal] = useState(false);
  const [utilityProps, setUtilityProps] = useState({});
  const [isLoading, setLoadingUtilities] = useState(true);
  const zipCode = queryParameters.get('zip') || newCallFormData.zipCode;
  const { utilityId } = newCallFormData;
  let origin_prefix = origin.split('.')[0];
  let redirect_url = origin_prefix.includes('localhost') ? origin_prefix : origin_prefix + '.digitalenergyby5.com'

  const triggerUtilityModal = () => {
    setOpenUtilityModal(!openUtilityModal);
  };

  const loadingComplete = () => {
    setLoadingUtilities(false);
  };
  const findUtilityForService = async () => {
    const where = new URLSearchParams({ 'where': JSON.stringify({ 'zipCode': zipCode }) });
    const status = await newCallActions.getUtility(where);
    if (status) {
      if (status >= 2) {
        triggerUtilityModal();
      }
    } else {
      triggerUtilityModal();
    }
    loadingComplete(false);
  };
  useEffect(() => {
    const findUtility = async () => {
      await masterAction.getMasterData();
      await findUtilityForService();
    };
    if (!utilityId) {
      findUtility();
    } else {
      loadingComplete(false);
    }
  }, []);

  const onChangeHandler = (utilityPicked) => {
    setUtilityProps({ utilityPicked });
  };

  const anyUtilities = (utility.length > 0);
  const getUtilitiesNames = () => {
    if (anyUtilities)
      return (utility.map((option) => ({ ...option, value: option.id, label: option.name })));
  }

  const updateStore = () => {
    const utilityIdObj = utilityProps.utilityIdObj || utility[0];

    setNewCall({
      ...newCallFormData,
      utilityId: utilityIdObj.id,
      utilityIdObj,
    });
  };

  const nextStep = (continueHandler) => {
    return () => {
      if (!continueHandler.reRunFlow) {
        updateStore();
        triggerUtilityModal();
      } else {
        window.location.replace(redirect_url);
      }
    }
  };

  const anyUtilityPicked = (Object.keys(utilityProps).length > 0);
  return (
    <>
      <Grid item xs={12} className='LoadingContainer'>
        <Loading show={isLoading}></Loading>
      </Grid>
      <Modal
        open={openUtilityModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="utilities-modal-container">
          <Container maxWidth="md" className="">
            <Grid container spacing={2} className="">
              {anyUtilities &&
                <><Grid item xs={12} className="sectionTitleContainer d-flex d-flex-center-all description-fields-section">
                  <div className="utility-message">
                    <span>Multiple Utilities are available for this Zip code.</span>
                    <span>Please select which one you would like.</span>
                  </div>
                </Grid>
                  <Grid item xs={12} className="utility-select-box">
                    <FormControl fullWidth className="not-btn-style">
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        size="small"
                        value='Select one'
                        options={getUtilitiesNames()}
                        onChange={(e, props) => (props) ? onChangeHandler(props) : ''}
                        renderInput={(params) => <TextField required {...params} label="Utility" />}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="utilities-modal-actions">
                    <ThemeProvider theme={energyBy5Theme}>
                      <Button size="small" variant="contained" color="blue"
                        className={"btn-eb5 white-txt" + (anyUtilityPicked ? "" : " disabled")}
                        disabled={!anyUtilityPicked}
                        onClick={nextStep({ reRunFlow: false })}>
                        Continue
                      </Button>
                    </ThemeProvider>
                  </Grid></>
              }
              {!anyUtilities &&
                <><Grid item xs={12} className="sectionTitleContainer d-flex d-flex-center-all description-fields-section no-utilities-message">
                  <span className="message-title">Sorry!</span>
                  <span>There are no Utilities for this zip code.</span>
                </Grid>
                  <Grid item xs={12} className="utilities-modal-actions">
                    <ThemeProvider theme={energyBy5Theme}>
                      <Button size="small" variant="contained" color="secondary" className="btn-eb5 white-txt" onClick={nextStep({ reRunFlow: true })}>OK</Button>
                    </ThemeProvider>
                  </Grid></>
              }
            </Grid>
          </Container>
        </Box>
      </Modal>
    </>
  );
}

export default UtilityModal;
