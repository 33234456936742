import { React, useState, useEffect } from 'react'
import Container from '@mui/material/Container';
import { Typography, Tooltip, List, ListItem, ListItemText, ListItemButton, Modal } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import { CircularProgress } from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';
import { suppliersListAtom, termsListAtom, priceMatrixAtom, newCallAtom, masterAtom, priceMatrixSuccessAtom } from '../../../_state';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useUploadPriceActions } from '../../../_actions';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import Header from '../Components/Header';
import CloseIcon from '@mui/icons-material/Close';
import { width } from '@mui/system';
import { supplierListImages } from '../../../_common/app.constants';
const ClientLogo = process.env.PUBLIC_URL + "/images/client-logos/Engie-logo.png";
const infoIc = process.env.PUBLIC_URL + "/images/customer/info_ic_grey.png"
const LightBulb = process.env.PUBLIC_URL + "/images/customer/blue-light_bulb.png"

const style = {
    backgroundColor: '#fff',
    padding: '20px',
};
const Modalstyle = {
    position: 'absolute',
    top: '50% !important',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 6,
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        textAlign: 'center !important',
        padding: '6px 4px',
        border: '1px solid #0000ff !important',
        borderBottom: '5px solid #0000ff !important',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        cursor: 'pointer'
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const SearchCompare = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalText, setModalText] = useState(1);
    const navigate = useNavigate();
    const priceActions = useUploadPriceActions();
    const suppliersList = useRecoilValue(suppliersListAtom);
    const termsList = useRecoilValue(termsListAtom);
    const priceMatrix = useRecoilValue(priceMatrixAtom);
    const priceMatrixSuccess = useRecoilValue(priceMatrixSuccessAtom);
    const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
    const [cellColor, setCellColor] = useState();
    const { masterSetting } = useRecoilValue(masterAtom);
    const [suppliersListArrenge, setSuppliersList] = useState([]);
    const [checkBoxChecked, setCheckBoxChecked] = useState({});
    let origin_prefix = origin.split('.')[0];
    let redirect_url = origin_prefix.includes('localhost') ? origin_prefix : origin_prefix + '.digitalenergyby5.com'

    useEffect(() => {
        priceActions.resetPriceMatrix();
        priceActions.getPriceListAction();
    }, [newCallFormData.greenEnergy, newCallFormData.creditPreApproved])

    const handleCellClick = (row) => {
        setCellColor(row.id)
        setNewCall({
            ...newCallFormData,
            'selectedPrice': row,
            pricingId: row.id,
            selectedSupplier: { ...row.supplierId },
            margin: (masterSetting?.marginTerms === 'no') ? masterSetting['margin'] : masterSetting[`margin${row.term}m`],
            dealValue: (((masterSetting?.marginTerms === 'no') ? masterSetting['margin'] : masterSetting[`margin${row.term}m`]) * newCallFormData.annualUsage) * (row.term / 12).toFixed(2),
            annualValue: (((masterSetting?.marginTerms === 'no') ? masterSetting['margin'] : masterSetting[`margin${row.term}m`]) * newCallFormData.annualUsage).toFixed(2),
            rate: ((row.price * 100) + ((masterSetting?.marginTerms === 'no') ? masterSetting['margin'] : masterSetting[`margin${row.term}m`]) / 10).toFixed(2)
        })
        navigate('/contract-details')
    }
    useEffect(() => {
        let input = [...suppliersList];
        const staticSuppliersList = ['ENGIE', 'NRG', 'APG&E', 'CONSTELLATION', 'GREEN MOUNTAIN'];
        const sortAccording = (A1, A2) => {
            // Map to store the indices of elements in the second array
            let index = new Map();

            for (let i = 0; i < A2.length; i++) {
                // Consider only first occurrence of element
                if (!index.has(A2[i])) {
                    // Assign value of i+1
                    index.set(A2[i], i + 1);
                }
            }

            A1.sort((a, b) => {
                let idx1 = index.get(a.toUpperCase()) || 0;
                let idx2 = index.get(b.toUpperCase()) || 0;

                // If both a and b are not present in the second array, sort them in ascending order
                if (idx1 === 0 && idx2 === 0) {
                    return a - b;
                }

                // If only b is present in the second array, b comes before a
                if (idx1 === 0) {
                    return 1;
                }

                // If only a is present in the second array, a comes before b
                if (idx2 === 0) {
                    return -1;
                }

                // If both a and b are present in the second array, sort them according to their respective indices
                return idx1 - idx2;
            });
        }
        sortAccording(input, staticSuppliersList);
        setSuppliersList([...input])
    }, [suppliersList]);
    return (
        <>
            <div className="search-compare">
                <Container maxWidth="xl">
                    <Header />
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        color="text.primary"
                        className='section-title'
                        sx={{
                            mb: 3
                        }}
                    >
                        Search, Compare & Select Electricity Plans
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3} lg={2} align="center" className='advance-search'>
                            <TableContainer component={Paper}>
                                <Table >
                                    <TableHead >
                                        <TableRow>
                                            <TableCell style={{ padding: '23px 6px', borderColor: '#0000ff', borderBottom: '5px solid #0000ff', color: '#0000ff', fontSize: '16px' }}>Advanced Search</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableCell sx={{ padding: '20px 6px !important', paddingBottom: '0px !important' }}>
                                            <FormGroup sx={{ mt: 0 }}>
                                                <Typography component="h6" variant="h6" sx={{ mb: 2, mt: 0 }}>Features</Typography>
                                                <div className='radio-groups'>
                                                    <div className="form-group" style={{ marginBottom: '10px' }}>
                                                        <input type="checkbox"
                                                            onChange={e => setNewCall({
                                                                ...newCallFormData,
                                                                greenEnergy: e.target.checked
                                                            })}
                                                            checked={newCallFormData?.greenEnergy || false} id="radio3" name="greenEnergy" value="Green Energy" />
                                                        <label for="radio3">Green Energy</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="checkbox"
                                                            checked={newCallFormData?.creditPreApproved || false}
                                                            onChange={e => setNewCall({
                                                                ...newCallFormData,
                                                                creditPreApproved: e.target.checked
                                                            })}
                                                            id="radio4"
                                                            name="creditPreApproved"
                                                            value="Credit Pre-approved" />
                                                        <label for="radio4">Credit Pre-approved </label>
                                                    </div>
                                                    <span className='blue-light_bulb advance-box'>
                                                        <Tooltip title={<h3>lowest rate for the given term length</h3>} placement="top" arrow>
                                                            <><img style={{ width: '18px', height: '17px', display: 'inline-block' }} alt='blue-light_bulb' src={LightBulb} /> <span className='blub-text'> Lowest Rate for the given term length</span></>
                                                        </Tooltip>
                                                    </span>
                                                </div>
                                            </FormGroup>
                                        </TableCell>
                                    </TableBody>
                                </Table>
                                <Button variant="text" className='btn-link btn-reset-search'
                                    onClick={e => setNewCall({
                                        ...newCallFormData,
                                        creditPreApproved: false,
                                        greenEnergy: false
                                    })}
                                    fullWidth
                                >
                                    Reset Search</Button>
                            </TableContainer>

                            <TableContainer component={Paper} sx={{ margin: '20px 0' }}>
                                <Table>
                                    <TableHead >
                                        <TableRow>
                                            <TableCell style={{ padding: '23px 20px', borderColor: '#0000ff', borderBottom: '5px solid #0000ff', color: '#0000ff', fontSize: '16px' }}>FAQ </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableCell sx={{ padding: '20px !important' }}>
                                            <FormGroup>

                                                <Box className='faq-wrapper'>
                                                    <ListItem component="div">
                                                        <ListItemButton onClick={e => { setModalOpen(true); setModalText(1) }}>
                                                            <ListItemText>What is the early termination fee?</ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <ListItem component="div" onClick={e => { setModalOpen(true); setModalText(2) }}>
                                                        <ListItemButton>
                                                            <ListItemText>What is not included in these rates?</ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <ListItem component="div" onClick={e => { setModalOpen(true); setModalText(3) }}>
                                                        <ListItemButton>
                                                            <ListItemText>What are credit requirements?</ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>

                                                    <Modal
                                                        // hideBackdrop
                                                        open={modalOpen}
                                                        onClose={e => setModalOpen(false)}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >

                                                        <Box sx={{ ...Modalstyle }} className='faq_modal'>
                                                            <Button variant='contained' color='primary' className='modal-close btn-primary' onClick={e => setModalOpen(false)}>
                                                                <CloseIcon />
                                                            </Button>
                                                            {modalText === 1 &&
                                                                <Typography variant="body1" component="p" align="left" sx={{ mb: 3 }}>
                                                                    If you cancel your agreement before the contracted term is complete, you may be subject to an early termination fee.
                                                                    The calculation of this fee is usually the Contract Price less the Current Market Price of electricity multiplied by the amount of electric energy supply you failed to consume as a customer by exiting early.
                                                                    <br /><br />
                                                                    You can think of the early termination fee as a supplier recouping their losses from the defaulting partner
                                                                </Typography>
                                                            }
                                                            {modalText === 2 &&
                                                                <Typography variant="body1" component="p" align="left" sx={{ mb: 3 }}>
                                                                    Supplier offers only cover the supply portion of your invoice.  All charges from the utility will be passed through with no additional mark-up.  Transmission and Distribution charges from the utility are regulated by the State.
                                                                </Typography>
                                                            }
                                                            {modalText === 3 &&
                                                                <Typography variant="body1" component="p" align="left" sx={{ mb: 3 }}>
                                                                    For suppliers that require a credit check, usually 2-3 months of clean invoices will suffice to pass credit.  In the event that a supplier is unable to find your legal entity to run credit, they may also require a cash deposit, a personal guarantee or a business guarantee.  If you are concerned about not having enough credit history or passing credit, check the box for Credit Approved suppliers in the Advanced Search section
                                                                </Typography>
                                                            }

                                                        </Box>
                                                    </Modal>
                                                </Box>
                                            </FormGroup>
                                        </TableCell>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography variant="h6" mt={2} mb={1}>Have Questions?</Typography>
                            <Button variant="contained" color='primary' className='btn-primary'
                                onClick={e => window.open(
                                    redirect_url + '/contact/',
                                    '_blank'
                                )} >Contact Us </Button>

                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={10} className='shrink-auto supplier-logo-table'>
                            <TableContainer component={Paper}>
                                <Table hoverRow aria-label="customized table" className='table-w-auto'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ padding: '0px', borderColor: '#0000ff', borderBottom: '5px solid #0000ff', color: '#0000ff', textAlign: 'center', width: '110px' }}>Supplier / Term</StyledTableCell>
                                            {Object.keys(priceMatrix).length > 0 && suppliersListArrenge.map(item =>
                                                <StyledTableCell sx={{ padding: '0px', borderColor: '#0000ff', borderBottom: '5px solid #0000ff', color: '#0000ff', textAlign: 'center', width: '110px' }} align="center">
                                                    <span className='supplier_logo_wrapper'>
                                                        {supplierListImages[item] && supplierListImages[item]['image'] !== '' ?
                                                            <img className='supplier_logo' alt="ClientLogo" src={`${process.env.PUBLIC_URL}/images/client-logos/${supplierListImages[item]['image']}`} />
                                                            : item
                                                        }
                                                        {supplierListImages[item] && supplierListImages[item]['text'] !== '' &&
                                                            <Tooltip title={<h3>{supplierListImages[item]['text']}</h3>} placement="top" arrow><img style={{ width: '16px', display: 'inline-block' }} alt="infoIc" src={infoIc} /></Tooltip>
                                                        }
                                                    </span>
                                                </StyledTableCell>
                                            )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {Object.keys(priceMatrix).length > 0 &&
                                        <TableBody >
                                            {termsList.map((row) => (
                                                <StyledTableRow key={row}>
                                                    {(priceMatrix[row]) ?
                                                        <>
                                                            {suppliersListArrenge.map((item, index) =>
                                                                <>
                                                                    {index === 0 &&
                                                                        <StyledTableCell component="th" scope="row" sx={{ position: 'relative', textAlign: 'center', width: '110px' }}>
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', textAlign: 'center' }}>
                                                                                {row === 1 ?
                                                                                    <div style={{ "text-align": 'center' }}>
                                                                                        Fixed Rate Monthly Contract
                                                                                    </div>
                                                                                    :
                                                                                    `${row} Months`
                                                                                }
                                                                            </Box>
                                                                        </StyledTableCell>
                                                                    }
                                                                    {(priceMatrix[row][item] && priceMatrix[row][item].price) ?
                                                                        <StyledTableCell
                                                                            align="center"
                                                                            style={{ backgroundColor: (priceMatrix[row][item].id === cellColor) ? '#3B0DD0' : '', textAlign: 'center', width: '110px' }}
                                                                            onClick={() => handleCellClick((priceMatrix[row] && priceMatrix[row][item]))}
                                                                        >
                                                                            <span className='table_btn'>{((priceMatrix[row][item].price * 100) + ((masterSetting?.marginTerms === 'no') ? masterSetting['margin'] : masterSetting[`margin${row}m`]) / 10).toFixed(2)} <span className='small'>₵/kWh</span></span>
                                                                            {priceMatrix[row][item]?.smallestPrice &&
                                                                                <span className='blue-light_bulb'><img src={LightBulb} alt='blue-light_bulb' /></span>
                                                                            }
                                                                        </StyledTableCell>
                                                                        : <StyledTableCell align="center" sx={{ textAlign: 'center' }} >-</StyledTableCell>
                                                                    }
                                                                </>
                                                            )}
                                                        </> : ''
                                                    }
                                                </StyledTableRow>))}
                                        </TableBody>
                                    }
                                    {Object.keys(priceMatrix).length <= 0 &&
                                        <>
                                            {priceMatrixSuccess ?
                                                <TableBody sx={{ position: "relative", minHeight: '200px', display: 'block', textAlign: 'center' }}>
                                                    <StyledTableRow sx={{ justifyContent: 'center', alignItems: 'center', display: 'block' }}>
                                                        <Box sx={{ padding: "7%" }}>
                                                            No offers available for the selected conditions
                                                        </Box>
                                                    </StyledTableRow>
                                                </TableBody>
                                                :
                                                <TableBody sx={{ position: "relative", minHeight: '200px', display: 'block' }}>
                                                    <StyledTableRow>
                                                        <Backdrop
                                                            sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                            open={true}
                                                        >
                                                            <CircularProgress color="inherit" />
                                                        </Backdrop>
                                                    </StyledTableRow>
                                                </TableBody>
                                            }
                                        </>
                                    }
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>




            </div>
        </>
    )
}

export default SearchCompare;
