import { React, useState, useEffect } from 'react'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import { Box } from '@mui/material';
import { ListItem, ListItemText } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useNewCallActions } from '../../../_actions';
import { newCallAtom } from '../../../_state';
import { useRecoilValue } from 'recoil';
import LoadingButton from '@mui/lab/LoadingButton';
import '../custom.css';


const ContractReview = () => {
  const newCallActions = useNewCallActions();
  const newCallData = useRecoilValue(newCallAtom);
  const [contractDetails, setContractDetails] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate();
  const createData = (name, value) => {
    return { name, value };
  }
  const {
    rate,
    termLength,
    selectedSupplier,
    contactInformation,
    billingAddress,
    serviceAccountList } = newCallData;
  const serviceAccountStr = serviceAccountList.reduce(
    (accumulator, currentValue) => {
      const { city, state, zipCode, address } = currentValue;
      const separator = accumulator ? ' and ' : '';
      return address ?
        `${accumulator}${separator}${address}, ${city}, ${state}, ${zipCode}`
        : '';
    },
    '',
  );
  const { city, state, zipCode, address } = billingAddress;
  useEffect(() => {
    window.scrollTo(0, 0);
    const contract = [
      createData('Supplier Name:', selectedSupplier?.name),
      createData('Rate:', `${rate} ¢/kWh`),
      createData('Term:', `${termLength}`),
      createData('Service Address:', serviceAccountStr),
      createData('First Name:', contactInformation?.firstName || ''),
      createData('Last Name:', contactInformation?.lastName || ''),
      createData('Email:', contactInformation?.email || ''),
      createData('Phone Number:', contactInformation?.phoneNumber || ''),
      createData('Job Title:', contactInformation.jobTitle || ''),
      createData('Legal Entity Name:', contactInformation?.legalEntityName || ''),
      createData('Federal Tax ID (EIN):', contactInformation?.federalTaxId || ''),
      createData('Billing Address:', `${address}, ${city}, ${state}, ${zipCode}` || ''),
    ];
    setContractDetails([...contract])
  }, [newCallData])

  const onSubmit = async () => {
    setIsSubmitting(true)
    await newCallActions.sendDocuSign();
    setIsSubmitting(false)
  }
  return (
    <>
      <div className='contract-review'>
        <Container maxWidth="md">
          <Box
            sx={{ maxWidth: 600, margin: 'auto', bgcolor: '#fff', p: { md: 4, sm: 3, sx: 2 } }}
            className="information-container summary-box bg-gray"
          >
            <Grid container spacing={0} >
              <Grid item xs={12} className='title-container'>
                <Typography variant="h2" component="h2" align="center" className='section-title' sx={{ mt: { sm: 2, sx: 2 } }} mb={2}>Please Review Your Information</Typography>
              </Grid>
              {/* Section G */}
              <Grid item xs={12} className='info-container'>
                <ListItem component="div" className='contract-review-list'>
                  {contractDetails.map((row) => (
                    <ListItemText >
                      <span>{row.name}</span> <span>{row.value}</span>
                    </ListItemText>
                  ))}
                </ListItem>
                <Grid item xs={12} align="center">
                  <LoadingButton
                    className={`signcontract-button btn-eb5 width-full bordered ${isSubmitting ? 'disabled' : ''}`}
                    type={'button'}
                    onClick={e => onSubmit()}
                    fullWidth
                    loading={isSubmitting}
                    loadingPosition="end"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isSubmitting}
                    label={isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  >
                    Sign Agreement
                  </LoadingButton>
                  <Button
                    className="editcontract-button btn-eb5 width-full bordered"
                    variant="outlined"
                    size='large'
                    onClick={() => navigate('/offer-summary/')}
                  >Edit</Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div >
    </>

  )
}

export default ContractReview;
