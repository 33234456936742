
import { React, useEffect } from 'react';
import { Button, Grid, FormControl, Container, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import '../custom.css';
import { useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import moment from 'moment';
import HeadphonesIcon from '@mui/icons-material/Headphones';

const ContactUs = () => {
  let isToday = false;
  const [newCallFormData] = useRecoilState(newCallAtom);
  const requestedStartDate = moment(newCallFormData.requestedStartDate, "DD/MM/YYYY");

  if (requestedStartDate.isSame(new Date(), "day")) {
    isToday = true;
  }
  useEffect(() => {
  }, [])

  return (
    <div className="shop-now">
      <Container maxWidth="md">
        <Grid container spacing={5}>
          <Grid item xs={12} className="sectionTitleContainer">
            <h2>You qualify for a Custom Offer</h2>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer">
            <p>There are better options for your business that
              one of our energy advisers could help you with.
              An energy expert will gladly provide you with advise,
              please select how you would like to be contacted:</p>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name=""
              >
                <FormControlLabel value="notknow" control={<Radio />} label="Schedule a Zoom Call" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer">
            <Button variant="outlined" className='btn-eb5 bordered'>
              <HeadphonesIcon />
              Talk to an Advisor Now
            </Button>
          </Grid>
          <Grid item xs={6} className="alignLeft pt50">
            <Button variant="outlined" className="btn-eb5 width-full bordered" > Back</Button>
          </Grid>
          <Grid item xs={6} className="alignRight pt50">
            <Button variant="outlined" className="btn-eb5 width-full bordered">
              {isToday ? 'Continues with Priority Move In' : 'Next'}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default ContactUs;
