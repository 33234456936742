import { atom } from 'recoil';

const affiliateAtom = atom({
    key: 'affiliate',
    default: []
});

const affiliatesAtom = atom({
    key: 'affiliates',
    default: []
});

const affiliateErrorAtom = atom({
    key: 'affiliatesError',
    default: false,   
});

export { affiliateAtom, affiliatesAtom, affiliateErrorAtom };