import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { useRecoilValue } from 'recoil';
import { ESIDListAtom } from '../../_state';
import { useLocation } from '../../_actions';
import { Box, TextField, Container, FormControl, Typography, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const EsidLookUp = () => {
  const esIdOptionList = useRecoilValue(ESIDListAtom);
  const [newCallFormData, setNewCallFormData] = useState({});
  const locationActions = useLocation();

  const onEsidDataSetHandler = async (esIdData) => {
    let updateSetNewCall = {};
    updateSetNewCall['ESID'] = esIdData;
    setNewCallFormData({
      ...newCallFormData
      , ...updateSetNewCall
    });
  }

  
  console.log(newCallFormData,'newCallFormData')
  return (
    <>
      <div className="esid-look-up">
        <Container maxWidth='lg'>
          <Typography variant="h2" component="h2" className='section-title' sx={{ mt: { sm: 2, sx: 2 } }} mb={2}><span style={{ color: '#0000ff' }}>ESID</span> Look Up</Typography>
          <Typography variant="p" component="p" sx={{ mt: { sm: 2, sx: 2 } }} mb={2}>An ESID, or electric service identifier, is used by the utility to identify where to deliver electricity. The ESID is unique to the service address and is different than the meter number. To find out the ESID, type in your service address  in the look-up tool below.</Typography>
          <div className="look-up-search">
            <Autocomplete
              noOptionsText={"Not found, continue without address"}
              disablePortal
              fullWidth
              id="esid-look-up"
              size="large"
              value={newCallFormData?.ESID?.label || ''}
              onInputChange={(e) => locationActions.esidLookup(e?.target?.value)}
              options={esIdOptionList.map((option) => ({
                ...option,
                label: `${option._source.address.trim()},${option._source.city.trim()},${option._source.state.trim()} ${parseInt(String(option._source.zipcode).match(/\d{5}/g))} | ${option._source.esiid.trim()}`
              }))
              }
              onChange={(e, props) => onEsidDataSetHandler(props)
              }
              renderInput={(params) => <>
                <TextField
                  hiddenLabel
                  // defaultValue="Normal"
                  placeholder='Street address or ESID'
                  variant="filled"
                  {...params}
                />
                <IconButton type="button" sx={{ p: '10px', position: 'absolute', top: '6px', right: '15px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </>
              }

            />
          </div>
          
            <div className="look-up-results">
            {newCallFormData?.ESID && 
              <div className="look-up-results-inner">
                <div className="look-up-results-itm">
                  ESID: <span>{newCallFormData?.ESID._source?.esiid}</span>
                </div>
                <div className="look-up-results-itm">
                  Address: <span>{`${newCallFormData.ESID._source.address.trim()},
                  ${newCallFormData.ESID._source.city.trim()},
                  ${newCallFormData.ESID._source.state.trim()} 
                  ${parseInt(String(newCallFormData.ESID._source.zipcode).match(/\d{5}/g))}`}</span>
                </div>
                <div className="look-up-results-itm">
                  Status: <span>{newCallFormData.ESID._source.status}</span>
                </div>
                <div className="look-up-results-itm">
                  Premise Type: <span>{newCallFormData.ESID._source.premise_type}</span>
                </div>
                <div className="look-up-results-itm">
                  Utility: <span>{newCallFormData.ESID._source.utility}</span>
                </div>
                <div className="look-up-results-itm">
                  Load Zone: <span>{newCallFormData.ESID._source.zone}</span>
                </div>
              </div>
            }
            </div>          
        </Container>
      </div>
    </>
  );
};

export default EsidLookUp;
