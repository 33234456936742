import { React } from 'react'
import { useRecoilValue } from 'recoil';
import { ESIDListAtom } from '../../../_state';
import { useLocation } from '../../../_actions';
import { TextField, FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const AddressAutocomplete = (props) => {
  const { name, label, index, value, changeState, errors, required, disabled } = props;
  const locationActions = useLocation();
  const esIdOptionList = useRecoilValue(ESIDListAtom);
  const onEsidDataSetHandler = async (esIdData) => {
    let newAddress = {
      address: "",
      state: "",
      city: "",
      zipCode: 0,
      ESID: "",
    }
    if (esIdData) {
      const { _source } = esIdData;
      if (_source?.zipcode || '') {
        newAddress.zipCode = parseInt(String(_source?.zipcode).match(/\d{5}/g));
        newAddress.state = _source.state;
        newAddress.city = _source.city;
        newAddress.address = _source.address;
        newAddress.ESID = _source.esiid;
      }
    } else {
      newAddress.zipCode = '';
      newAddress.state = '';
      newAddress.city = '';
      newAddress.address = '';
      newAddress.ESID = '';
    }
    changeState(newAddress, index);
  }

  return (
    <FormControl fullWidth className="field-container">
      <Autocomplete
        className='custom-autocomplete'
        name={name}
        noOptionsText={"Not found, continue without address"}
        disablePortal
        disabled={disabled}
        value={value}
        onInputChange={(e) => locationActions.esidLookup(e?.target?.value)}
        options={esIdOptionList.map((option) => ({
          ...option,
          label: `${option._source.address.trim()},${option._source.city.trim()},${option._source.state.trim()} ${parseInt(String(option._source.zipcode).match(/\d{5}/g))} | ${option._source.esiid.trim()}`
        }))
        }
        onChange={(e, props) => {
          console.log('propiedades: ', e, props)
          onEsidDataSetHandler(props)
        }
        }
        renderInput={(params) => <TextField  {...params} label={label} />}
      />
      <div className="invalid-feedback" style={{ color: "red" }}>
        {errors.serviceAccountList ? errors.serviceAccountList[index].address?.message : ''}
      </div>
    </FormControl>
  );
}

export default AddressAutocomplete;
