import { atom } from 'recoil';

const masterAtom = atom({
    key: 'masterData',
    default: {leadSource:[],utility:[], zone:[],masterSetting:[],loadFactor:[],}
});

const userMarginAtom = atom({
    key: 'masterError',
    default: false,   
});

export { 
    masterAtom, userMarginAtom
};