import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import ConfirmationScreen from "./Shop/ConfirmationScreen";
import NewCallDetails from "./Shop/NewCallDeatails";
import SearchCompare from "./Shop/SearchCompare";
import ShopNow from './Shop/ShopNow';
import ShopLarge from './Shop/ShopLarge';
import Docusign from "./DocuSign";
import EsidLookUp from "./EsidLookUp";
import Campaign from "./Campaign";
import { useNavigate, useLocation } from 'react-router-dom';
import SelectServiceType from './workflow/SelectServiceType';
import PickStartDate from './workflow/PickStartDate';
import EnergyConsumption from './workflow/EnergyConsumption';
import ContactUs from './workflow/ContactUs';
import ExtraInformation from './workflow/ExtraInformation';
import GetRates from './workflow/GetRates';
import ThankYou from './workflow/ThankYou';
import ExtraInformation2 from './workflow/ExtraInformation2';
import EnterZip from './workflow/EnterZip';
import OfferSummary from './workflow/OfferSummary';
import ContractReview from './workflow/ContractReview';

const Customer = ({ history, match }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const where = new URLSearchParams(location.search);
    if (where.get('leadSourceId')) {
      const leadSourceId = where.get('leadSourceId');
      localStorage.removeItem('leadSourceId');
      localStorage.setItem('leadSourceId', (leadSourceId) ? window.atob(leadSourceId) : '');
      navigate('/');
    }
  }, [location.search]);

  return (
    <div className="customer-panel">
      <Routes>
        <Route path={`/`} exact element={<Home />} />
        <Route path={`/select-service/`} exact element={<SelectServiceType />} />
        <Route path={`/pick-start-date/`} exact element={<PickStartDate />} />
        <Route path={`/energy-consumption/`} exact element={<EnergyConsumption />} />
        <Route path={`/contact-us/`} exact element={<ContactUs />} />
        // <Route path={`/extra-information/`} exact element={<ExtraInformation />} />
        // <Route path={`/extra-information-2/`} exact element={<ExtraInformation2 />} />
        <Route path={`/enter-zip/`} exact element={<EnterZip />} />
        <Route path={'/get-rates'} exact element={<GetRates/>} />
        <Route path={'/offer-summary'} exact element={<OfferSummary/>} />
        <Route path={'/contract-review'} exact element={<ContractReview/>} />
        <Route path={'/thank-you'} exact element={<ThankYou/>} />
        <Route path={`/step-2/`} exact element={<ShopNow />} />
        {/* <Route path={`/small-commercial-electricity-zip-code-lookup/`} exact element={<Home />} /> */}
        <Route path={`/start-date-details/`} exact element={<ShopNow />} />
        <Route path={`/get-rates2/`} exact element={<SearchCompare />} />
        <Route path={`/contract-details/`} exact element={<NewCallDetails />} />
        <Route path={`/review-docusign/`} exact element={<ContractReview />} />
        <Route path={`/confirmation-screen/`} exact element={<ConfirmationScreen />} />
        <Route path={`/large/`} exact element={<ShopLarge />} />
        <Route path={`/large-commercial-electricity/`} exact element={<ShopLarge />} />
        <Route path={`/document-submitted/*`} exact element={<Docusign />} />
        <Route path={`/esid-look-up/`} exact element={<EsidLookUp />} />
        <Route path={`/campaign/`} exact element={<Campaign />} />
      </Routes>
    </div>
  );
};

export default Customer;
