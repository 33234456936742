import React, { useEffect } from 'react'
import {Container} from '@mui/material';
import {  useNewCallActions,useAlertActions } from '../../_actions';
import { useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
const Docusign = () => {  
  const newCallActions = useNewCallActions();
  const location = useLocation(); 
  const alertActions = useAlertActions();       

  useEffect(() => {      
    const where = new URLSearchParams(location.search);
    const parsed = `${where.get('code')}/${location.search}`;
    const event = where.get('event');
    let origin_prefix = origin.split('.')[0];
    let redirect_url = origin_prefix.includes('localhost') ? origin_prefix : origin_prefix + '.digitalenergyby5.com'

      if(event==='signing_complete'){
      newCallActions.updateDocuSign(parsed);  
    }else{      
      setTimeout((e)=>{
        alertActions.error('Session TimeOut, Please try to fill again');
      },1);    
      window.location.href=redirect_url;
      return true;
    }      
  },[])  
  return (
      <>
        <div className='large-comercial'>
            <Container maxWidth="xl">
                <Backdrop
                  sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={true}
                >
                  <CircularProgress color="inherit" />
              </Backdrop>
            </Container>
        </div>
      </>    
  );
};

export default Docusign;
