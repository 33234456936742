import { useRecoilState, useSetRecoilState, useResetRecoilState, useRecoilValue } from 'recoil';
import { useFetchWrapper } from '../_helpers';
import { masterAtom, userMarginAtom } from '../_state';
import { useAlertActions } from '../_actions/alert.actions';
import { Labels, Apis } from '../_common/app.constants'

const useMasterActions = () => {
    const fetchWrapper = useFetchWrapper();
    const alertActions = useAlertActions();
    const [masterData, setMasterData] = useRecoilState(masterAtom);
    const setMarginError = useSetRecoilState(userMarginAtom)

    const getMasterData = (params = '') => {
        return fetchWrapper.get(`${Apis.Master_Data}?${params}`)
            .then((res) => {
                (res.statusCode === Labels.Success_Status_Code)
                    ?
                    setMasterData({
                        ...masterData,
                        leadSource: res.data.loadFactor,
                        zone: res.data.zone,
                        masterSetting: res.data.masterSetting,
                        loadFactor: res.data.loadFactor
                    })
                    :
                    alertActions.error(res.msg);
            });
    }

    const setMargin = (data) => {
        return fetchWrapper.patch(`${Apis.Set_Static_Margin}/${data.id}`, data)
            .then(res => {
                if (res.statusCode === 200) {
                    alertActions.success(Labels.Text_Update_Success);
                    setMarginError(true)
                } else {
                    alertActions.error(res.msg);
                }
            })
    }

    return {
        getMasterData,
        setMargin,
        resetMasterData: useResetRecoilState(masterAtom),
    }

}
export { useMasterActions };
