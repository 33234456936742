import { React, useEffect } from 'react';
import { Button, Grid, Container} from '@mui/material';
import { useNavigate } from "react-router-dom";
import '../custom.css';
import { useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import ButtonIcon from '../Components/ButtonIcon';
import Steps from '../Components/Steps';
import Loading from '../Components/Loading';

const ExtraInformation2 = () => {
  const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
  const { customerPriority } = newCallFormData;
  const navigate = useNavigate();

  useEffect(() => {
  }, [])
  return (
    <div className="shop-now">
      <Container maxWidth="md">
        <Loading message="Getting your best electricity offers" show={false}></Loading>
        <Grid container spacing={2}>
          <Grid item xs={12} className="sectionTitleContainer">
              <Steps position={5} total={7}></Steps>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer">
            <h2>What is more important for your business?</h2>
          </Grid>

          <Grid item xs={12} className="question">
            <div className='radio-buttons'>
              <ButtonIcon message="SHORTER TERM LENGTH AND LOWEST COST"
                icon='/images/Step 5/Shorter Term.png'
                deselect={!(customerPriority=='shorter')} onClick={()=>{setNewCall({
                  ...newCallFormData,
                  'customerPriority': 'shorter'
                })
                navigate('/get-rates/')
                }}></ButtonIcon>
              <ButtonIcon message="LONGER TERM LENGTH AND A KNOWN VALUE TO BUDGET EXPENSES" 
                icon='/images/Step 5/Longer Term.png'
                deselect={!(customerPriority=='longer')} onClick={()=>{setNewCall({
                  ...newCallFormData,
                  'customerPriority': 'longer'
                })
                navigate('/get-rates/')
                }}></ButtonIcon>
            </div>
          </Grid>
          <Grid item xs={6} className="alignLeft pt50">
            <Button
              onClick={() => navigate('/extra-information/')}
              variant="outlined"
              className="btn-eb5 width-full bordered"
            > Back</Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default ExtraInformation2;
