/**
 * Usage eg.
 * message:string   - this will be show on Button
 * icon:string      - this is the path to the image to show in the button
 * deselect:boolean[optional] - this change the style to a delecte state if true
 * onClick:function  - this is the function to execute on click
 * 
 * import Loading from '../Components/ButtonIcon';
 * 
 * <ButtonIcon
        message="String"
        icon="/images/Step 1/New Contract.png"
        deselect={flagDeselect}
        onClick={clickHandler({kindOfService: 'new'})}></ButtonIcon>
 * 
 */
import React from 'react';

const ButtonIcon = (props) => {
    return (
        <div className={(props.deselect)?'button-icon disabled':'button-icon' } onClick={props.onClick}>
            {props.icon &&(<div className='img-icon'>
                    <img
                    src={`${process.env.PUBLIC_URL}${props.icon}`}
                    width="80"
                    height="80"
                    alt="Icon" />
                    </div>
                    )
                }
            <h2>{props.message}</h2>
        </div>
    )
}

export default ButtonIcon
