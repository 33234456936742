/**
 * Usage eg.
 * message:string - this will be show on top
 * show:boolean - this will determine if the Loading component is shown
 * 
 * import Loading from '../Components/Loading';
 * 
 * <Loading message={messageLoading} show={showLoading}></Loading>
 * 
 */
import React from 'react';

const Loading = (props) => {
    return props.show && (
        <div className='loading'>
            <h2 display={props.message ? "none" : "block"}>{props.message}</h2>
            <img
                className='image-loading'
                src={`${process.env.PUBLIC_URL}/images/BulbLoadingAnimationColor.gif`}
                width="380"
                height="380"
                alt="Loading" />
        </div>
    )
}

export default Loading
