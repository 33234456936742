import { BrowserRouter as Router,Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Customer from './Pages/Customer/Customer';
import { history, fakeBackend } from './_helpers'
import { alertAtom } from './_state/';
import { SnackbarProvider } from 'notistack';
const App = () => { 
  const open = useRecoilValue(alertAtom);
  // setup fake backend
  fakeBackend();
  return (
     <Router history={history} locationProp={history.location} navigator={history}>
         <SnackbarProvider  
         autoHideDuration={1000}        
         anchorOrigin={{ vertical:'top', horizontal:'right' }}
         open={open}
         >
            <Routes>            
                  <Route path="*"  exact element={<Customer />}  />            
            </Routes> 
         </SnackbarProvider>
     </Router> 
  );
};

export default App;
