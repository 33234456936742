import { atom } from 'recoil';

const leadAtom = atom({
    key: 'lead',
    default: []
});

const leadsAtom = atom({
    key: 'leads',
    default: []
});

const leadErrorAtom = atom({
    key: 'leadError',
    default: false,   
});

export { leadAtom, leadsAtom, leadErrorAtom };