import { atom } from "recoil";
import dayjs from "dayjs";
const newCallAtom = atom({
  key: "newCallFormData",
  default: {
    //not in use
    // loadFactorId: null,
    // invoice: "n",
    // signedLOA: "n",
    // loadFactorIdObj: { name: "", value: "" },
    // utilityIdArr: [],
    // leadSourceIdObj: { name: "", value: "" },
    // selectedPrice: {},
    // serviceStateChecked: "Yes",
    // inquiryServiceLocation: [],
    // addMeter: [],

    invoice: "n",
    signedLOA: "n",
    status: "started",
    serviceState: "",
    margin: 0.0,
    zoneIdObj: { name: "", value: "" },
    enrollmentType: "Standard Switch",
    status: "started",
    utilityIdObj: { name: "", value: "" },
    requestedStartDate: undefined, //in use
    annualUsage: 0, // in use
    monthlyUsage: 0, //in use
    annualValue: 0, //in use
    annualKwh: 0, //in use
    squareFeet: 0, //in use
    isNewService: "",
    zipCode: undefined,
    isServiceUrgent: false, //if user pick date that is within today and 3 days before
    dontKnow: false, // in case user dont know how much energy they need
    accountsManagement: false, //extra information question
    customerPriority: "dont", //extra information question
    utilityOpen: false,
    utilityId: 0,
    termLength: 'All months',
    pricingId: undefined,
    selectedSupplier: { id: 0, name: "" },
    dealValue: 0,
    rate: 0,
    greenEnergy: false,
    creditPreApproved: false,
    step1: false,
    billingServiceAddressCheck: false,
    referralCode: '',
    contactInformation: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      legalEntityName: "",
      jobTitle: "",
    },
    billingAddress: {
      address: "",
      city: "",
      state: "",
      zipCode: "",
      ESID: "",
    },
    serviceAccountList: [
      {
        address: "",
        state: "",
        city: "",
        zipCode: 0,
        ESID: "",
      },
    ],
  },
});

export { newCallAtom };
