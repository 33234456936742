import { React, useEffect } from 'react'
import { Grid, Container } from '@mui/material';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useLocation as useLocationAction } from '../../../_actions';
import { newCallAtom } from '../../../_state';
import { useRecoilState } from 'recoil';
import '../custom.css';
import ButtonIcon from '../Components/ButtonIcon';
import Steps from '../Components/Steps';
import UtilityModal from './UtilityModal';

const SelectServiceType = () => {
  const getLocation = useLocationAction();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
  const navigate = useNavigate();
  const zipCode = queryParameters.get('zip') || newCallFormData.zipCode || '';
  const serviceState = getLocation.getState(zipCode)?.st || '';

  function setLocationInfo() {
    setNewCall({
      ...newCallFormData,
      ...{
        zipCode: zipCode,
        serviceState: serviceState
      }
    });
  }

  useEffect(() => {
    if (serviceState == '') {
      newCallFormData.referralCode ?
        navigate("/enter-zip/")
        : navigate("/small-commercial-electricity-zip-code-lookup/")
    }
    else {
      setLocationInfo()
      if (serviceState != 'TX')
        navigate("/pick-start-date/")
    }
  }, []);

  const runSetNewCall = (kindOfService) => {
    setNewCall({
      ...newCallFormData,
      ...{
        isNewService: kindOfService,
        step1: true,
        utilityOpen: false
      }
    });
  };

  const clickHandler = (valueService) => {
    return () => {
      runSetNewCall(valueService.kindOfService);
    }
  };

  return (
    newCallFormData.step1 ?
      <Navigate to="/pick-start-date" />
      :
      <div className="shop-now">
        <Grid item xs={12} className="sectionTitleContainer">
          <Steps position={1} total={5}></Steps>
        </Grid>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} className="sectionTitleContainer">
              <h2>How can we help you?</h2>
            </Grid>
            <Grid item xs={6} className="alignRight">
              <ButtonIcon
                message="I WANT TO CHANGE ELECTRICITY SUPPLIERS OR RENEW MY CURRENT CONTRACT"
                icon="/images/Step 1/Renewal.png"
                onClick={clickHandler({ kindOfService: 'renew' })}></ButtonIcon>
            </Grid>
            <Grid item xs={6} className="alignLeft">
              <ButtonIcon
                message="I DO NOT HAVE AN ELECTRICITY CONTRACT"
                icon="/images/Step 1/New Contract.png"
                onClick={clickHandler({ kindOfService: 'new' })}></ButtonIcon>
            </Grid>
          </Grid>
        </Container>
        <UtilityModal />
      </div>
  );
}

export default SelectServiceType;
