import { React, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { newCallAtom, ESIDListAtom, masterAtom } from '../../../_state';
import CloseIcon from '@mui/icons-material/Close';
import { useNewCallActions, useLocation, useMasterActions } from '../../../_actions';
import { Typography, Box, Button, TextField, Container,  FormControl, Modal, Grid } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import dayjs from 'dayjs';

const style = {
    backgroundColor: '#fff',
    padding: '20px',
};
const Modalstyle = {
    position: 'absolute',
    top: '50% !important',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 6,
};

const Shop = () => {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({        
        serviceZIP: Yup.string()
        .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code')
        .required("Zip code is required"),       
    })
    //yup validation
    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build form with useForm() hook
    const { handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    
    const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
    const locationActions = useLocation();
    const newCallActions = useNewCallActions();
    const esIdOptionList = useRecoilValue(ESIDListAtom);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalBodyText, setModalBodyText] = useState({ text: '', type: '' });
    const masterApiAction = useMasterActions();
    const { masterSetting, utility } = useRecoilValue(masterAtom);    
    console.log('Options List: ',esIdOptionList);
    const onEsidDataSetHandler = async (esIdData) => {
        let updateSetNewCall = {};
        if (esIdData) {
            const { _source } = esIdData;
            if (_source?.zipcode || '') {
                updateSetNewCall['serviceZIP'] = parseInt(String(_source?.zipcode).match(/\d{5}/g));
                updateSetNewCall['serviceState'] = _source.state;
                updateSetNewCall['serviceCity'] = _source.city;
                updateSetNewCall['serviceAddress'] = _source.address;
                updateSetNewCall['utilityAccountNumber'] = _source.esiid;
                updateSetNewCall['textBox1'] = _source.esiid;
                updateSetNewCall['duns'] = _source.duns;
                updateSetNewCall['premiseType'] = _source.premise_type;
                if (_source.premise_type === 'Residential') {
                    setModalOpen(true);
                    setModalBodyText({ text: 'This looks to be a residential meter.  Residential meters are unable to be contracted under a commercial plan, even if the business operates out of the residence', type: '' });
                }
            }
            updateSetNewCall['ESID'] = esIdData
        } else {
            updateSetNewCall['serviceZIP'] = '';
            updateSetNewCall['serviceCity'] = '';
            updateSetNewCall['serviceAddress'] = '';
            updateSetNewCall['utilityAccountNumber'] = '';
            updateSetNewCall['textBox1'] = '';
            updateSetNewCall['duns'] = '';
            updateSetNewCall['premiseType'] = '';
            updateSetNewCall['ESID'] = ''
        }
        setNewCall({
            ...newCallFormData,
            ...updateSetNewCall
        })
    }

    useEffect(() => {
        reset({...newCallFormData})
    }, [newCallFormData])
   

    const onSubmit = async () => {
        const where = new URLSearchParams({ 'where': JSON.stringify({ "zipCode": newCallFormData.serviceZIP }) })
        const status = await newCallActions.getUtility(where);
        if (status) {
            if (status >= 2) {
                setModalOpen(true);
                setModalBodyText({ text: 'Multiple Utilities are available for this Zip code.', type: 'utility' })
            } else {
                setNewCall({
                    ...newCallFormData,
                    requestedStartDate:(newCallFormData.serviceState==="TX")?dayjs():newCallFormData.requestedStartDate
                })                
                await newCallActions.createNewCall();
                navigate(`/start-date-details`);
            }
        } else {
            setModalOpen(true);
            setModalBodyText({ text: 'No pricing available for this Utilty.', type: '' });
        }
    }
    return (
        <>
            <div className='shop-search'>
                <Container maxWidth="md">
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        color="text.primary"
                        className='section-title'
                        sx={{
                            mb: 3
                        }}
                    >
                        Shop for electricity
                    </Typography>
                    <Box
                        sx={{
                            width: 600,
                            maxWidth: '100%',
                            display: 'flex',
                            gap: '10px',
                            margin: 'auto',
                        }}
                    >
                        <TextField
                            fullWidth
                            label="Enter your zip code"
                            size='small'
                            InputLabelProps={{ shrink: newCallFormData?.serviceZIP ? true : false }}
                            value={newCallFormData?.serviceZIP || ''}
                            required
                            onChange={(e) =>{ 
                                reset({...newCallFormData})
                                setNewCall({
                                    ...newCallFormData,
                                    'serviceZIP': e.target.value,
                                    'serviceState': locationActions.getState(e.target.value)?.st || '',
                                    monthlyUsage: 0,                                    
                                })
                            }
                        }
                        />    
                    </Box>
                    <Box
                        sx={{
                            width: 600,
                            maxWidth: '100%',
                            display: 'flex',
                            gap: '20px',
                            margin: 'auto',
                        }}
                    >
                        <div  className="invalid-feedback" style={{ color: "red" }}>{errors.serviceZIP?.message}</div>
                    </Box>
                    
                    {newCallFormData.serviceState === 'TX' &&
                        <>
                            <Box
                                sx={{
                                    width: 600,
                                    maxWidth: '100%',
                                    marginTop: '30px',
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    textAlign: 'center'
                                }}
                            >
                                <h3 className='text-center'>Commercial Service Address or ESID</h3>
                                <FormControl fullWidth sx={{ bgcolor: '#fff' }} className='not-btn-style'>
                                    <Autocomplete
                                        noOptionsText={"Not found, continue without address"}
                                        disablePortal
                                        //disabled={((newCallFormData.serviceStateChecked === 'Yes') ? false : true)}
                                        fullWidth
                                        id="combo-box-demo"
                                        size="small"
                                        value={newCallFormData?.ESID?.label || ''}
                                        onInputChange={(e) => locationActions.esidLookup(e?.target?.value)}
                                        options={esIdOptionList.map((option) => ({
                                            ...option,
                                            label: `${option._source.address.trim()},${option._source.city.trim()},${option._source.state.trim()} ${parseInt(String(option._source.zipcode).match(/\d{5}/g))} | ${option._source.esiid.trim()}`
                                        }))
                                        }
                                        onChange={(e, props) => onEsidDataSetHandler(props)
                                        }
                                        renderInput={(params) => <TextField  {...params} label="Street address or ESID" />}
                                    />
                                </FormControl>
                            </Box>
                            <div className="enrollment_type">
                                <h3 className='text-center'>Enrollment Type</h3>
                                <div className='radio-groups'>
                                    <div className="form-group">
                                        <input type="radio" id="radio1" name="Enrollment_Type" value="Standard Switch" onChange={e => setNewCall({
                                            ...newCallFormData,
                                            enrollmentType: e.target.value,
                                            creditPreApproved: false
                                        })}
                                            checked={newCallFormData?.enrollmentType === 'Standard Switch' ? true : ''}
                                        />
                                        <label for="radio1">Renew existing contract/switch providers</label>
                                    </div>
                                    <div className="form-group">
                                        <input type="radio" id="radio2" name="Enrollment_Type" value="Move In" onChange={e => setNewCall({
                                            ...newCallFormData,
                                            enrollmentType: e.target.value,
                                            creditPreApproved: true
                                        })}
                                            checked={newCallFormData?.enrollmentType === 'Move In' ? true : ''}
                                        />
                                        <label for="radio2">Move into a new location</label>
                                    </div>
                                    <div className="form-group">
                                        <input type="radio" id="radio3" name="Enrollment_Type" value="Priority Move-In" onChange={e => setNewCall({
                                            ...newCallFormData,
                                            enrollmentType: e.target.value,
                                            creditPreApproved: false
                                        })}
                                            checked={newCallFormData?.enrollmentType === 'Priority Move-In' ? true : ''}
                                        />
                                        <label for="radio3">I need power ASAP</label>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <Box
                        sx={{
                            width: 600,
                            maxWidth: '100%',
                            marginTop: '30px',
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: 'center'
                        }}
                    >
                        <Button type="submit" className='btn-primary' color="primary" variant="contained" sx={{ minWidth: '150px' }} onClick={handleSubmit(onSubmit)} >Continue</Button>
                    </Box>
                </Container>
                <Modal
                    open={modalOpen}
                    onClose={(e, reason) => (reason !== 'backdropClick') ? setModalOpen(false) : ''}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <>
                        {modalBodyText.type == '' &&
                            <Box sx={{ ...Modalstyle }} className='faq_modal'>
                                <Button variant='contained' color='primary' className='modal-close btn-primary' onClick={e => setModalOpen(false)}>
                                    <CloseIcon />
                                </Button>
                                <Typography variant="h5" component="p" align="center" sx={{ fontSize: '16px', mb: 3, fontWeight: '600', color: 'red' }}>
                                    {modalBodyText.text}
                                </Typography>
                            </Box>
                        }
                        {modalBodyText.type === 'utility' &&
                            <Box sx={{ ...Modalstyle }} className='faq_modal'>
                                <Typography variant="h5" component="p" align="center" sx={{ fontSize: '16px', mb: 3, fontWeight: '600', color: 'red' }}>
                                    {modalBodyText.text}
                                </Typography>
                                {utility?.length >= 1 &&
                                    <>
                                        <Grid container spacing={2} >
                                            <Grid item xs={12}>
                                                <FormControl fullWidth sx={{ bgcolor: '#fff' }} className='not-btn-style'>
                                                    <Autocomplete
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        id="combo-box-demo"
                                                        size="small"
                                                        value={newCallFormData?.utilityIdObj?.label || ''}
                                                        options={utility.map((option) => ({ ...option, label: option.name }))}
                                                        onChange={(e, props) => (props) ? setNewCall({ ...newCallFormData, utilityOpen: false, 'utilityIdObj': props, utilityId: props?.id }) : ''}
                                                        renderInput={(params) => <TextField required {...params} label="Utility" />}
                                                    />
                                                </FormControl>
                                            </Grid>                                          
                                            <Grid item xs={12}>
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        marginTop: 2,
                                                    }}
                                                >
                                                    <div><Button variant='contained' color='primary' className='btn-primary' onClick={e => { setModalOpen(false); navigate(`/start-date-details`); }}>Save</Button></div>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                }


                            </Box>
                        }
                    </>
                </Modal>
            </div >
        </>
    )
}

export default Shop;
