import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { useFetchWrapper } from "../_helpers";
import { newCallAtom, masterAtom } from "../_state";
import { useAlertActions } from "./alert.actions";
import { Apis } from "../_common/app.constants";

const useNewCallActions = () => {
  const navigate = useNavigate();
  const fetchWrapper = useFetchWrapper();
  const alertActions = useAlertActions();
  const [formData, setNewCall] = useRecoilState(newCallAtom);
  const setMasterData = useSetRecoilState(masterAtom);
  const origin = window.location.origin;
  let origin_prefix = origin.split(".")[0];
  let redirect_url = origin_prefix.includes("localhost")
    ? origin_prefix
    : origin_prefix + ".digitalenergyby5.com";

  const setPostData = () => {
    let postData = structuredClone(formData);
    postData.contactInformation.phoneNumber =
      postData.contactInformation.phoneNumber.replace(/\D+/g, "");
    postData["supplierName"] = postData?.selectedPrice?.supplierId?.name || "";
    postData["utilityName"] = postData?.utilityIdObj?.label || "";
    postData["customerName"] = postData?.contactInformation.firstName | "";
    postData["customerEmail"] = postData?.contactInformation.email || "";
    postData["customerPhone"] = postData?.contactInformation.phoneNumber || "";
    postData["leadSourceName"] = postData?.leadSourceIdObj?.label || "";
    postData["zoneName"] = postData?.zoneIdObj?.label || "";
    postData["loadFactorName"] = postData?.loadFactorIdObj?.label || "";
    postData["leadSourcePhone"] = postData?.leadSourceIdObj?.phone || "";
    postData["promo"] = localStorage.getItem("promo");
    postData["leadSourceId"] = localStorage.getItem("leadSourceId");
    return postData;
  };
  const createNewCall = () => {
    return fetchWrapper
      .post(Apis.Create_New_Call, setPostData())
      .then((res) => {
        if (res.statusCode === 200) {
          const { data } = res;
          const { customer, inquiry } = data;
          setNewCall((currentState) => {
            return {
              ...res.data,
              ...currentState,
              customerId: customer.id,
              inquiryId: inquiry.id,
            };
          });
        }
      });
  };
  const sendDocuSign = async () => {
    return await fetchWrapper
      .post(Apis.Send_Docu_Sign, setPostData())
      .then((res) => {
        if (res.statusCode === 200) {
          setNewCall({ ...res.data, ...formData });
          if (res?.data?.docuSignUrl) {
            window.location.href = res?.data?.docuSignUrl;
          } else {
            let url = "";
            switch (res.data.enrollmentType) {
              case "Standard Switch":
                url = redirect_url + "/thank-you/";
                break;
              case "Move In":
                url = redirect_url + "/thank-you-moving/";
                break;
              case "Priority Move-In":
                url = redirect_url + "/thank-you-asap/";
                break;
              default:
                url = redirect_url + "/thank-you/";
                break;
            }

            window.open(url, '_blank');
            //window.location.href = url;
          }
        } else {
          alertActions.error(
            "Please try again later, or contact your system Administrator."
          );
        }
      });
  };

  const getzipToUtility = (params = "") => {
    return fetchWrapper.get(`${Apis.Zip_To_Utility}?${params}`).then((res) => {
      if (res.statusCode === 200) {
        let utilityArr = res.data
          ? res.data?.map((obj, key) => {
              return obj?.utilityId;
            })
          : [];

        setMasterData((obj) => {
          return { ...obj, utility: utilityArr ? utilityArr : obj.utility };
        });
      }
    });
  };

  const getUtility = (params = "") => {
    return fetchWrapper.get(`${Apis.Get_Utility}?${params}`).then((res) => {
      if (res.statusCode === 200) {
        if (res?.data?.utilityList.length) {
          setTimeout(() => {
            const { utilityList, loadZone } = res.data;
            setNewCall((currentState) => {
              return {
                ...currentState,
                utilityIdObj: {
                  ...utilityList[0],
                  label: utilityList[0]?.name,
                },
                utilityId: utilityList[0]?.id,
                zoneIdObj: { ...loadZone, label: loadZone?.name },
                zoneId: loadZone?.id,
              };
            });
          }, 1);
          setMasterData((obj) => {
            return { ...obj, utility: [...res.data.utilityList], ...res?.data };
          });
          return res?.data?.utilityList.length;
        } else {
          return 0;
        }
      }
    });
  };

  const updateDocuSign = (params = "") => {
    return fetchWrapper
      .get(`${Apis.Update_Docu_Sign}/${params}`)
      .then((res) => {
        if (res.statusCode === 200) {
          let url = "";
          switch (res.data.enrollmentType) {
            case "Standard Switch":
              url = redirect_url + "/thank-you/";
              break;
            case "Move In":
              url = redirect_url + "/thank-you-moving/";
              break;
            case "Priority Move-In":
              url = redirect_url + "/thank-you-asap/";
              break;
            default:
              url = redirect_url + "/thank-you/";
              break;
          }
          window.open(url, '_blank');
          //window.location.href = url;
        } else {
          alertActions.error(res.msg);
        }
      });
  };

  return {
    createNewCall,
    sendDocuSign,
    resetNewCallAtom: useResetRecoilState(newCallAtom),
    getzipToUtility,
    getUtility,
    updateDocuSign,
  };
};

export { useNewCallActions };
