import { useSetRecoilState, useRecoilState, useResetRecoilState } from 'recoil';
import { useFetchWrapper } from '../_helpers';
import { suppliersListAtom, termsListAtom, priceMatrixAtom, newCallAtom, uploadSuccessAtom,priceMatrixSuccessAtom } from '../_state';
import { useAlertActions } from './alert.actions';
import { Labels, Apis } from '../_common/app.constants'
import dayjs from 'dayjs';

const useUploadPriceActions = () => {
    const fetchWrapper = useFetchWrapper();
    const alertActions = useAlertActions();
    const [newCallData, setNewCallData] = useRecoilState(newCallAtom);
    const setSuppliersList = useSetRecoilState(suppliersListAtom);
    const setTermsList = useSetRecoilState(termsListAtom);
    const setPriceMatrix = useSetRecoilState(priceMatrixAtom);
    const setPriceMatrixSuccess = useSetRecoilState(priceMatrixSuccessAtom);
    const setUploadSuccess = useSetRecoilState(uploadSuccessAtom);
    const uploadPriceAction = (data) => {
        return fetchWrapper.post(Apis.Upload_Price_Sam, data, true)
            .then(res => {
                if (res.statusCode === 200) {
                    setUploadSuccess(true)
                    setTimeout(()=>{
                        alertActions.success(Labels.Upload_Success);
                    },1000)
                } else {
                    setUploadSuccess(false)
                    alertActions.error(res.msg);
                }
            })
    }

    const getPriceListAction = () => {
        setPriceMatrixSuccess(false);
        let queryWhere = {};
        let annualUsage = 0;
        const {
            annualKwh, termLength, isServiceUrgent, enrollmentType, serviceState, dontKnow, squareFeet,
            utilityId, requestedStartDate, zoneId, loadFactorId, greenEnergy, creditPreApproved, monthlyUsage } = newCallData;
        const priceTerm = termLength || 0;
        const firstDayMonthDate = `${dayjs(requestedStartDate).format('YYYY')}/${dayjs(requestedStartDate).format('MM')}/01`;
        const enrolmentType = isServiceUrgent ? Labels.Text_Enrollment_Type : enrollmentType;

        if (dontKnow || (monthlyUsage === 'lessThan')) {
            annualUsage = '75.00'; // 75,000 kWh per default
        }
        if (squareFeet) {
            annualUsage = (((parseFloat(squareFeet) * 10) / 1000)).toFixed(2);
        }
        if (annualKwh) {
            annualUsage = (parseFloat(annualKwh) / 1000).toFixed(2);
        }
        setNewCallData({
            ...newCallData,
            annualUsage
        });

        if (enrolmentType === Labels.Text_Enrollment_Type){
            queryWhere =   { term: 1 };
        } else {
            let or = [
                { and: [
                    { maxSize: {'>=':  annualUsage} },
                    { minSize: { '<=': annualUsage} },
                ]},
                { and:[
                    { maxSize: 0 },
                    { minSize: 0 },
                ]}
            ];
            queryWhere = {
                state: serviceState,
                contractStartMonth: firstDayMonthDate,
                utilityId
            };

            if (serviceState ==='TX') {
                const zoneIdArray =  { or: [
                    { zoneId: null },
                    { zoneId: zoneId },
                    { zoneId: { "!=": null } }
                ]};
                const loadFactorIdArray = { or: [
                    { loadFactorId: null },
                    { loadFactorId: loadFactorId },
                    { loadFactorId: { "!=": null } }
                ]};
                queryWhere['and'] =  []
                queryWhere['and'].push(zoneIdArray);
                queryWhere['and'].push(loadFactorIdArray);
            }

            queryWhere['or'] = or;

            if(greenEnergy){
                queryWhere['green'] = { ">": 0 };
            }
    
            if(creditPreApproved){
                queryWhere['preApprovedCredit'] = 1;
            }

            if (priceTerm > 0) {
                queryWhere['term'] = priceTerm;
            }
        }
        // Params mocked.
        // queryWhere = {"state":"TX","utilityId":1939,"contractStartMonth":"2024/01/01","or":[{"and":[{"maxSize":{">=":"75.00"}},{"minSize":{"<=":"75.00"}}]},{"and":[{"maxSize":0},{"minSize":0}]}]};
        const sendData = new URLSearchParams({
            skip: 0,
            limit:1000,
            where: JSON.stringify(queryWhere),
            enrollmentType : enrolmentType
        });

        return fetchWrapper.get(`${Apis.Upload_Price}?${sendData}`)
            .then(res => {
                if (res.statusCode === 200) {  
                    const termsList = (enrollmentType!==Labels.Text_Enrollment_Type)?[1,6,12,18,24,36,48,60]:[1];
                    let suppliers = [];
                    let priceMatrix = {};                    
                    for (const row of res.data) {
                        if (!priceMatrix[row.term])
                            priceMatrix[row.term] = {};
                        if(!priceMatrix[row.term][row.supplierId.name]){
                            priceMatrix[row.term][row.supplierId.name] = row;
                        }                        
                        suppliers.push(row.supplierId.name);
                    }
                    for(const term in priceMatrix){
                        let supplierName = null;
                        let smallestPrice = null;
                        for(const supplier in priceMatrix[term]){
                            if(!smallestPrice || priceMatrix[term][supplier].price < smallestPrice){
                                supplierName = priceMatrix[term][supplier].supplierId.name;
                                smallestPrice = priceMatrix[term][supplier].price;
                            }
                        }
                        priceMatrix[term][supplierName]["smallestPrice"] = true;
                    }
                    
                    suppliers = Array.from(new Set(suppliers));                   
                    setPriceMatrixSuccess(true);
                    return { suppliers, termsList, priceMatrix };
                } else {
                    setPriceMatrixSuccess(false);
                    alertActions.error(res.msg);
                }
            });
    };

    const findExtraPriceInfoAction = (data) => {
        const { annualKwh } = data;
        const { annualUsage } = newCallData;

        if (annualKwh === 0) {
            data.annualUsage = annualUsage;
            data.annualKwh = (parseFloat(annualUsage) * 1000).toString();
        } else {
            data.annualUsage = (parseFloat(annualKwh) / 1000).toFixed(2);
        }

        return fetchWrapper.post(`${Apis.Find_Extra_Info_Price}`, data)
            .then(res => {
                if (res.statusCode === 200) {
                    const { offerCompareData: extraPriceInformation, pricingRateRanges} = res.data || [];
                    if (extraPriceInformation.length > 0) {
                        extraPriceInformation.forEach((extraInfoItem) => {
                            const pricingDetailsParsed = extraInfoItem.pricingDetails ? JSON.parse(extraInfoItem.pricingDetails) : undefined;
                            const infoBubbleParsed = extraInfoItem.infoBubble ? JSON.parse(extraInfoItem.infoBubble) : undefined;
                            extraInfoItem.pricingDetails = pricingDetailsParsed;
                            extraInfoItem.infoBubble = infoBubbleParsed;
                        });
                    }
                    return ({ extraPriceInformation, pricingRateRanges });
                } else {
                    alertActions.error(res.msg);
                }
            })
    };

    return {
        findExtraPriceInfoAction,
        uploadPriceAction,
        getPriceListAction,
        resetPriceMatrix: useResetRecoilState(priceMatrixAtom),
    };
}

export { useUploadPriceActions };
