import { useState, useEffect } from 'react';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Button } from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import InfoIcon from '@mui/icons-material/Info';

const UtilityAccountNumberReference = (props) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [listOfImages, setListOfImages] = useState([{path: "",state:"",label:''}]);
  const newCallData = useRecoilValue(newCallAtom);

  const importAll = (r) => {
    let images = [];
    let state = (["CT", "HO", "IL", "MA", "MD", "NJ", "NY", "PA"]).find(e => e === newCallData.serviceState.toUpperCase());
      r.keys().forEach((item, index) => {
      if (item.split("/")[1] === newCallData?.utilityIdObj?.utility_account_number?.referencePicture) {
        images.push({
          path: '/' + item.substring(item.lastIndexOf('/') + 1),
          state: (state) ? state : "",
          label: item.substring(item.lastIndexOf('/') + 1).replace(/-/gi, " ").replace(/.jpg/gi, "")
        });
      }
    });
    setListOfImages(images.length?images:listOfImages);
    return images
  }

  useEffect(() => {
    importAll(require.context("../../../../public/images/UANReferenceImages/", true, /\.(png|jpg|svg)$/));
  }, [])

  useEffect(() => {
    importAll(require.context("../../../../public/images/UANReferenceImages/", true, /\.(png|jpg|svg)$/));
  }, [props])

  console.log(listOfImages)

  return (
    <>
      {newCallData?.utilityIdObj?.utility_account_number?.referencePicture && 
        <InfoIcon className="ic-info" onClick={() => setIsOpen(true)} />
      }
      {isOpen && (
        <Lightbox
          imageTitle={`${listOfImages[photoIndex]['label']}`}
          mainSrc={`${process.env.PUBLIC_URL}/images/UANReferenceImages/${listOfImages[photoIndex]['path']}`}
          nextSrc={`${listOfImages[(photoIndex + 1) % listOfImages.length]}`}
          prevSrc={`${listOfImages[(photoIndex + listOfImages.length - 1) % listOfImages.length]['path']}`}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + listOfImages.length - 1) % listOfImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % listOfImages.length)
          }
        />
      )}
    </>
  );
}

export default UtilityAccountNumberReference;