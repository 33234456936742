import { atom } from 'recoil';

const uploadPriceAtom = atom({
    key: 'uploadPriceData',
    default: []
});

const uploadSuccessAtom = atom({
    key: 'uploadSuccessAtom',
    default: false
});

const uploadPriceListAtom = atom({
    key: 'uploadPriceListAtom',
    default: []
});
const suppliersListAtom = atom({
    key: 'suppliersListAtom',
    default: []
});
const termsListAtom = atom({
    key: 'termsListAtom',
    default: []
});
const priceMatrixAtom = atom({
    key: 'priceMatrixAtom',
    default: {}
})

const priceMatrixSuccessAtom = atom({
    key: 'priceMatrixSuccessAtom',
    default: false
});

const ESIDListAtom = atom({
    key: 'ESIDList',
    default: []
})
export {
    uploadPriceAtom,
    uploadPriceListAtom,
    suppliersListAtom,
    termsListAtom,
    priceMatrixAtom,
    ESIDListAtom,
    uploadSuccessAtom,
    priceMatrixSuccessAtom
};