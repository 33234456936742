export const Labels = {
    Success: 'success',
    Error: 'error',
    Login_Success: "Login successfully",
    Upload_Success: "Uploaded successfully",
    Password_Reset_Email: 'Password reset link has been sent to your XXXX successfully',
    Password_Reset_Success: "Your password has been reset successfully",
    Password_Change_Success: "Your password has been change successfully",
    Logout_Success: "Logout successfully",
    Success_Status_Code: 200,
    Error_Status_Code: 500,
    Local_Storage_User: 'user',
    Local_Storage_LeadSourceId: 'leadSourceId',
    Role_Type_Admin: 'admin',
    Role_Type_Sam: 'SAM_user',
    Role_Type_Referral_Partner: "referral_partner",
    Role_Type_Promo: "promo",
    Replce_Text: "XXXX",
    Text_Sam: 'SAM',
    Text_Admin: 'admin',
    Text_Add: "add",
    Text_Edit: "edit",
    Text_Sam_User_Menu: "SAM User Menu",
    Text_Admin_Menu: "Admin Menu",
    Text_Logout: "Logout",
    Text_Login: "Login",
    Text_Change_Password: 'Change Password',
    Text_Settings: 'Settings',
    Text_Profile: 'Profile',
    Text_Transaction_Log: 'Transaction Log',
    Text_Order_Log: 'Order Log',
    Text_Dashboard: "Dashboard",
    Text_New_Call: 'New Call',
    Text_Logs: "Logs",
    Text_Master_Data: "Master Data",
    Text_Upload_Price: "Upload Price",
    Text_Contract_Info: "Contract Info",
    Text_Set_Static_Margin_Info: "Set Static Margin",
    Text_Manage_Entities: "Manage Entities",
    Text_Add_Deal_Participant: "Add Deal Participant",
    Text_Add_Lead_Source: "Add Lead Source",
    Text_Add_Affiliate_or_Campaign: "Add Affiliate or Campaign",
    Text_Form_Fill_Enquiry: "Form Fill Enquiry",
    Text_Sam_Users: 'SAM Users',
    Text_New_Call_Success: "Successfully Submitted!",
    Text_Add_Suceess: 'Successfully Added!',
    Text_Update_Success: 'Successfully Updated!',
    Text_Delete_Success: 'Successfully Deleted!',
    Text_Enrollment_Type: 'Priority Move-In',   
    Text_TX: 'TX',   
    // Text_Large_Inquiry: 'ShopLarge',
}



export const Apis = {
    Create_New_Call: 'customerNewCall',
    Master_Data: 'getMasterData',    
    Upload_Price: 'findPricing/',   
    Send_Docu_Sign:'customerDocuSign',    
    Set_Static_Margin:'mastersetting', 
    Find_Price:'findPricing',
    Find_Extra_Info_Price: 'findExtraInfoPrice',
    Zip_To_Utility:'ziptoutility',
    Get_Utility:'findUtility',
    Large_Inquiry:'largeInquiry',
    Update_Docu_Sign:'docusign'    
}

export const supplierListImages = {
    "APG&E":{    
        name: "APG&E",
        logoId: "apge",
        image:'New_APGE_Logo (002).jpg',
        text: <>
            Supplier: APG&E<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: None</>
    },
    "ENGIE":{    
        name: "ENGIE",
        logoId: "engie",
        image:'Engie-logo.png',
        text: <>
            Supplier: Engie<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: None<br/>
            Credit Requirements: No Credit Check</>
    },
    "Vistra":{   
        name: "Vistra",
        logoId: "txuenergy",
        image:'TXU_Energy_Logo-300x157.jpg',
        text: <>
        Supplier: Vistra<br/>
        </>       
    },
    "AEP":{   
        name: "AEP", 
        logoId: "americanelectricpower",
        image:'AEP_logo.svg.png',
        text: <>
            Supplier: AEP<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: None</>     
    },
    "Green Mountain":{    
        name: "Green Mountain",
        logoId: "greenmountainenergy",
        image:'green-mountain-energy.png',
        text:<>
            Supplier: Green Mountain<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: $4.95/Month<br/>
            Renewable Energy: 100% Green</>       
    },
    "Gexa":{    
        name: "Gexa",
        logoId: "gexaenergy",
        image:'Gexa_Logo.jpg',
        text:<>
            Supplier: Gexa<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: None</>       
    },
    "Harbor":{    
        name: "Harbor", 
        logoId: "energyharbor",
        image:'Energy_Harbor_Logo_Logo.jpg',
        text:<>
            Supplier: Energy Harbor<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: None</>    
    },
    "SFE":{   
        name: "SFE Energy",
        logoId: "sfe",
        image:'SFElogo.jpg',
        text: <>
            Supplier: SFE<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: None</>     
    },
    "Constellation":{    
        name: "Constellation",
        logoId: "constellation",
        image:'CEG_BIG-e99423a6-300x50.png',
        text:<>
            Supplier: Constellation<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: None</>    
    },
    "Direct Energy":{   
        name: "Direct Energy",
        logoId: "directenergy",
        image:'1200px-Direct_Energy_logo.svg.png',
        text: ''      
    },
    "NRG":{
        name: "NRG",
        logoId: "nrg",
        image:'1200px-NRG_Energy_logo.svg.png',
        text: <>
            Supplier: NRG<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: None<br/>
            Credit Requirements: No Credit Check</> 
    },
    "NRG Green":{
        name: "NRG Green",
        logoId: "nrggreen",
        image:'Green_1200px-NRG_Energy_logo.png',
        text: <>
            Supplier: NRG Green<br/>
            Early Termination Fee: Varies (see FAQ)<br/>
            Additional Supplier Charges: None<br/>
            Credit Requirements: No Credit Check<br/>
            Renewable Energy: 100% Green</>
    }
};
