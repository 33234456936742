import { React, useEffect } from 'react';
import { Button, Grid, Container } from '@mui/material';
import { useNavigate } from "react-router-dom";
import '../custom.css';
import { useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import ButtonIcon from '../Components/ButtonIcon';
import Steps from '../Components/Steps';
import Loading from '../Components/Loading';

const ExtraInformation = () => {
  const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
  const navigate = useNavigate();

  useEffect(() => {
  }, [])
  return (
    <div className="shop-now">
      <Container maxWidth="md">
        <Loading message="Getting your best electricity offers" show={false}></Loading>
        <Grid container spacing={2}>
          <Grid item xs={12} className="sectionTitleContainer">
            <Steps position={4} total={7}></Steps>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer">
            <h2>Do you need the ability to add or delete electricity accounts from this contract after it is signed?</h2>
          </Grid>
          <Grid item xs={12} className="question">
            <div className='radio-buttons'>
              <ButtonIcon message="Yes"
                icon='/images/Step 4/Yes.png'
                deselect={!(newCallFormData.accountsManagement == 'yes')} onClick={() => {
                  setNewCall({
                    ...newCallFormData,
                    'accountsManagement': 'yes'
                  })
                  navigate('/extra-information-2/')
                }}></ButtonIcon>
              <ButtonIcon message="No"
                icon='/images/Step 4/No.png'
                deselect={!(newCallFormData.accountsManagement == 'no')} onClick={() => {
                  setNewCall({
                    ...newCallFormData,
                    'accountsManagement': 'no'
                  })
                  navigate('/extra-information-2/')
                }}></ButtonIcon>
            </div>
          </Grid>
          <Grid item xs={6} className="alignLeft pt50">
            <Button
              onClick={() => navigate('/energy-consumption/')}
              variant="outlined"
              className="btn-eb5 width-full bordered"
            > Back</Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default ExtraInformation;
