import { React } from 'react';
import { Button, TextField, Grid, FormControl, Container, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import '../custom.css';
import { useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import Steps from '../Components/Steps';

const EnergyConsumption = () => {
  const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
  const navigate = useNavigate();
  const { dontKnow, annualKwh, squareFeet, monthlyUsage } = newCallFormData;
  const navigationFlag = (annualKwh > 2000000 || squareFeet > 200000 || monthlyUsage === "moreThan")
  const navigateLink = navigationFlag ? '/large-commercial-electricity/' : '/get-rates/';
  let disableFlag = (dontKnow || annualKwh || squareFeet || monthlyUsage);

  return (
    <div className="shop-now">
      <Grid item xs={12} className="sectionTitleContainer">
        <Steps position={3} total={5}></Steps>
      </Grid>
      <Container maxWidth="md">
        <Grid container spacing={5}>
          <Grid item xs={12} className="sectionTitleContainer">
            <h2>Let’s estimate your energy consumption</h2>
            <label className="completeLabel">Please complete <b>one of</b> the fields below to help us get the best offer for your business.</label>
          </Grid>
          <Grid item xs={4} className="sectionTitleContainer">
            <FormControl fullWidth size="small" className='not-btn-style'>
              <label className="inputLabel big oneline short">Annual Electricity Usage in <b>kWh</b></label>
              <TextField
                id="kwh"
                type="number"
                value={newCallFormData?.annualKwh || ''}
                onChange={(val) => setNewCall({
                  ...newCallFormData,
                  'dontKnow': false,
                  'monthlyUsage': 0,
                  'squareFeet': 0,
                  'annualKwh': val.target.value
                })}
                variant="outlined" />
            </FormControl>
          </Grid>
          <Grid item xs={4} className="sectionTitleContainer">
            <FormControl fullWidth size="small" className='not-btn-style'>
              <label className="inputLabel big oneline">Approximate <b>Square Footage</b> of Your Facility</label>
              <div className='or-label'>or</div>
              <TextField
                id="sqft"
                value={newCallFormData?.squareFeet || ''}
                type="number"
                onChange={(val) => setNewCall({
                  ...newCallFormData,
                  'dontKnow': false,
                  'monthlyUsage': 0,
                  'annualKwh': 0,
                  'squareFeet': val.target.value
                })}
                variant="outlined" />
            </FormControl>
          </Grid>
          <Grid item xs={4} className="sectionTitleContainer">
            <FormControl fullWidth className=''>
              <label className="inputLabel big fix-tooltip">
                Average Monthly <b>Electricity Bill</b>
                <Tooltip placement="top" className="tooltip" title="Electricity pricing is highly dependent on the 
                    amount of electricity used in each month. A more accurate estimate on monthly 
                    electricity expenses will produce a more precise electricity rate.">
                  <label className='tooltipIcon-small'>&#9432;</label>
                </Tooltip>
              </label>
              <div className='or-label'>or</div>
              <Select
                className='alignLeft'
                id="monthlyUsage"
                labelId="monthlyUsageLabel"
                value={newCallFormData?.monthlyUsage || ''}
                onChange={(val) => setNewCall({
                  ...newCallFormData,
                  'dontKnow': false,
                  'annualKwh': 0,
                  'squareFeet': 0,
                  'monthlyUsage': val.target.value,
                })}
              >
                <MenuItem value="lessThan"> Less than $5,000 </MenuItem>
                <MenuItem value="moreThan"> More than $5,000 </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer">
            <FormControl>
              <RadioGroup
                value={newCallFormData?.dontKnow ? 'dontKnow' : ''}
                onChange={(val) => setNewCall({
                  ...newCallFormData,
                  'dontKnow': val.currentTarget.value === 'dontKnow' ? true : false,
                  'annualKwh': 0,
                  'monthlyUsage': 0,
                  'squareFeet': 0
                })}
              >
                <FormControlLabel
                  name="dontKnow"
                  value="dontKnow"
                  control={<Radio />}
                  label="I don't know"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6} className="alignLeft pt50">
            <Button
              variant="outlined"
              className="btn-eb5 width-full bordered"
              onClick={() => { navigate('/pick-start-date/') }}
            > Back</Button>
          </Grid>
          <Grid item xs={6} className="alignRight pt50">
            <Button
              variant="outlined"
              className={`btn-eb5 width-full bordered ${!disableFlag ? "disable" : 'next'}`}
              disabled={!disableFlag ? true : false}
              onClick={() => { navigate(navigateLink) }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default EnergyConsumption;
