
import { useFetchWrapper } from '../_helpers';
import { useAlertActions } from './alert.actions';
import { Labels, Apis } from '../_common/app.constants'
const useLargeAction = () => {
  const fetchWrapper = useFetchWrapper();
  let origin_prefix = origin.split('.')[0];
  let redirect_url = origin_prefix.includes('localhost') ? origin_prefix : origin_prefix + '.digitalenergyby5.com'
  const alertActions = useAlertActions();
  const setPostData = (data) =>{
    let postData = {...data};   
    const sendData = new FormData()     
    for (const value of Object.keys(postData)) {        
      if(value!=='utilityInvoice')
           sendData.append([value], postData[value]);
    }    
    sendData.append('utilityInvoice',postData['utilityInvoice'][0]);  
    sendData.append('customerName',postData['contactFirstName']);  
    sendData.append('customerEmail',postData['contactEmail']);  
    sendData.append('customerPhone',postData['contactPhone']);  
    return sendData;
}   
  const add = (data) => { 
    return fetchWrapper.post(`${Apis.Large_Inquiry}`, setPostData(data),'true')
      .then(res => {
        if (res.statusCode === 200) {
          alertActions.success(Labels.Text_Update_Success);
          window.location.href = redirect_url + '/thank-you-large-commercial';
        } else {
          alertActions.error(res.msg);
        }
      })
  };

  return {
    add,
  }

}
export { useLargeAction };