import * as React from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useRecoilValue, useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { Tooltip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
const infoIc = process.env.PUBLIC_URL + "/images/customer/info_ic_grey.png"

const Header = () => {
  const navigate = useNavigate();
  const newCallFormData = useRecoilValue(newCallAtom);
  return (
    <>
      <div className="previous-value" style={{ marginBottom: '40px' }}>
        <Button onClick={e => navigate(-1)} sx={{ my: 1, }} variant="contained" color='primary' size='small' className='btn-primary'><ChevronLeftIcon />Go Back</Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#0000ff', borderColor: '#0000ff', color: '#fff' }}>
              <TableRow>
                <>
                  {newCallFormData.serviceZIP &&
                    <TableCell style={{ color: '#fff', padding: '10px', textAlign: 'center', width: '300px' }}>Zip Code</TableCell>
                  }
                  {newCallFormData.utilityAccountNumber && newCallFormData.serviceState === 'TX' &&
                    <>
                      <TableCell style={{ color: '#fff', padding: '10px', textAlign: 'center', width: '300px' }}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', width: '100%' }}>
                          ESID  <Tooltip title={<h3>An ESID (Electric Service Identifier) is how the utility identifies the meter that recieves electricity.  ESIDs are tied to service addresses which may or may not match the postal address.</h3>} placement="top" arrow><img style={{ width: '16px', display: 'inline-block', alignSelf: 'center' }} alt="infoIc" src={infoIc} /></Tooltip>
                        </span>
                      </TableCell>
                      <TableCell style={{ color: '#fff', padding: '10px', textAlign: 'center', width: '300px' }}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', width: '100%' }}>
                          Enrollment Type <Tooltip title={<h3>
                            Suppliers follow different booking protocols for different types of enrollment.
                            A Switch is designated for a meter that already has service under the correct legal entity name and is renewing with a new supply agreement. A Move-In (MVI) is when a meter needs to be energized or put into a new legal entity name.
                          </h3>} placement="top" arrow><img style={{ width: '16px', display: 'inline-block', alignSelf: 'center' }} alt="infoIc" src={infoIc} /></Tooltip>
                        </span>
                      </TableCell>
                    </>
                  }
                  {newCallFormData.monthlyUsage !== 0 &&
                    <TableCell style={{ color: '#fff', padding: '10px', textAlign: 'center', width: '300px' }}>
                      {newCallFormData.monthlyLabel}</TableCell>
                  }
                  {newCallFormData?.utilityIdObj?.name !== '' &&
                    <TableCell style={{ color: '#fff', padding: '10px', textAlign: 'center', width: '300px' }}>Utility</TableCell>
                  }
                  {newCallFormData.monthlyUsage !== 0 &&
                    <TableCell style={{ color: '#fff', padding: '10px', textAlign: 'center', width: '300px' }}>Start Date</TableCell>
                  }
                </>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {newCallFormData.serviceZIP &&
                  <TableCell style={{ textAlign: 'center' }}>{newCallFormData.serviceZIP}</TableCell>
                }
                {newCallFormData.utilityAccountNumber && newCallFormData.serviceState === 'TX' &&
                  <>
                    <TableCell style={{ textAlign: 'center' }}>{newCallFormData.ESID.label}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{newCallFormData.enrollmentType}</TableCell>
                  </>
                }
                {newCallFormData.monthlyUsage !== 0 &&
                  <TableCell style={{ textAlign: 'center' }}>{newCallFormData.monthlyUsage}</TableCell>
                }

                {newCallFormData?.utilityIdObj?.name !== '' &&
                  <TableCell style={{ textAlign: 'center' }}>{newCallFormData.utilityIdObj?.name}</TableCell>
                }

                {newCallFormData.monthlyUsage !== 0 &&
                  <TableCell style={{ textAlign: 'center' }}>{dayjs(newCallFormData?.requestedStartDate || new Date()).format((newCallFormData.monthlyLabel === 'Estimated Square Footage') ? 'MM/YYYY' : 'MM/DD/YYYY')}</TableCell>
                }
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Header;
