import React from 'react'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box, Card, CardContent } from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
const ConfirmationScreen = () => {
  const newCallFormData = useRecoilValue(newCallAtom);
  return (
    <>  
      {/* thanks-section */}
      <div className="thanks-section">
        <Container maxWidth="md">
          <Box>
            <Typography variant="h2" component="h2" align="center" mb={2}>Thanks for your order
            </Typography>
            <Typography variant="body1" component="p" align="center" mb={2}>
              Generic confirmation that the contract was received  
            </Typography>
            {(newCallFormData.enrollmentType==='Standard Switch' || newCallFormData.serviceState!=='TX') &&
            <Grid container spacing={3}>
              <Grid item md={12}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>                    
                    <Typography variant="body2">
                    We will submit your contract to the supplier and reach out to you if there are any issues.  Do not cancel your current agreement, the new supplier will take over seamlessly.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>                 
            </Grid>
            }
            {newCallFormData.enrollmentType==='Move In' &&
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      1.
                    </Typography>
                    <Typography variant="body2">
                      Call your local municipality to  confirm you have the correct permits in place - (include links to the different utility resources)                    
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      2.
                    </Typography>
                    <Typography variant="body2">
                    Call your utility to get an update on the status of your move in order (include phone numbers for each utility)                    </Typography>
                  </CardContent>
                </Card>
              </Grid>              
            </Grid>
            }
            {newCallFormData.enrollmentType==='Priority Move-In' &&
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      1.
                    </Typography>
                    <Typography variant="body2">
                      Call your local municipality to  confirm you have the correct permits in place - (include links to the different utility resources)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      2.
                    </Typography>
                    <Typography variant="body2">
                      Call your utility to get an update on the status of your move in order (include phone numbers for each utility)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      3.
                    </Typography>
                    <Typography variant="body2">
                      Once you get up and running with power, return to the website to shop for renewal agreements with all suppliers
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            }
          </Box>
        </Container>
      </div>
    </>

  )
}

export default ConfirmationScreen;