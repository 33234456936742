/**
 * Usage eg.
 * position:number - current position to select
 * total:number - total number to show
 * 
 * import Loading from '../Components/Steps';
 * 
 * <Steps position={number} total={number}></Steps>
 * 
 */
import React from 'react';

const Steps = (props) => {
    var classTxt = 'circle number';
    var selected = classTxt+' selected';
    var next = classTxt+' next-step';
    return (
        <>
            <h1 className='title-bar'>Compare Electricity Offers</h1>
            <div className='steps-bar' onClick={props.onClick}>
                <div className='line small'></div>
                <div className='circle small start'>►</div>
                {
                    [...Array(props.total)].map(
                        (item, idx)=> (<><div  key={'circle_'+idx} className='line '></div>
                    <div className={(idx==(props.position-1))?selected:(idx>(props.position-1))?next:classTxt}>{idx+1}</div>
                    <div className='line'></div></>))
                }
                <div className={((props.position-1)>=props.total)?'circle small end':'circle small end next-step'}>&#x2713;</div>
                <div className='line small'></div>
            </div>
        </>
    )
}

export default Steps
