import { React } from 'react';
import { Button, Grid, Container } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import '../custom.css';
import { useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';

const ThankYou = () => {
  const [newCallFormData] = useRecoilState(newCallAtom);
  const { isServiceUrgent } = newCallFormData;
  return (
    <div className="shop-now">
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} className="sectionTitleContainer displayF">
            <img src="/images/highFive.png" />
            <h2>Thanks for <span className='tcBlue'>your order!</span></h2>
          </Grid>
          <Grid item xs={12} className="thanks-content">
            {
              isServiceUrgent ?
                <div>
                  <h4 className='fnt-size24'>Next Steps:</h4>
                  <ol className='thanks-info'>
                    <li>Call your local municipality to determine if a permit is required for you new electricity service.</li>
                    <ul>
                      <l1>
                        <Button
                          className='btn-e5'
                          startIcon={<FileDownloadOutlinedIcon />}
                          variant="outlined" >
                          Download inspection info
                        </Button>
                      </l1>
                    </ul>
                    <li>Call your utility for questions and status of your move in order.</li>
                    <li>For any other questions contact us here.</li>
                    <ul><l1>(972) 807-8716</l1></ul>
                  </ol>
                </div>
                :
                <div>
                  <p>
                    We will submit your contact to th supplier and reach out to you if there are any issues.
                    <b className='fs-italic'>Do not cancel service with your current supplier.</b> Service will automatically switch
                    over to your new supplier.
                  </p>
                  <b>For any other questions call us here</b>
                  <p className='phone'>(972) 807-8716</p>
                  <b>Or you can chat with us now:</b>
                </div>
            }
          </Grid>
          <Grid item xs={6} className="alignLeft pt50">
            <Button
              variant="outlined"
              startIcon={<FormatListBulletedIcon />}
              className="buttonsWidthFull"
            > Take the Survey Now</Button>
          </Grid>
          <Grid item xs={6} className="alignRight pt50">
            <Button
              variant="outlined"
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default ThankYou;
