import { React } from 'react';
import { Button, Grid, FormControl, Container, TextField } from '@mui/material';
import '../custom.css';
import { useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import { useNavigate } from "react-router-dom";

const EnterZip = () => {
  const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const referralCode = urlParams.get('REFERRALCODE') || '';
  if (referralCode && !newCallFormData.referralCode) {
    setNewCall({ ...newCallFormData, referralCode: referralCode });
  }
  let zipCode;
  let businessName;

  function startFlow() {
    setNewCall({ ...newCallFormData, businessName: businessName });
    navigate('/select-service/?zip=' + zipCode);
  }


  return (
    <div className="shop-now">
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} className="sectionTitleContainer">
            <img className='bulbImage' src={`${process.env.PUBLIC_URL}/images/lightbulb-brain.png`}/>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer">
            <label><b>Please enter your Zip code to continue</b></label>
            <br/>
            <label className="zipFieldPhoneLabel">Or call (855)-954-4800 for help</label>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer">
            <FormControl fullWidth className="not-btn-style referralFieldsContainer">
              <Grid container spacing={2}>
                <TextField item xs={6}
                  autoFocus
                  required
                  id="zipField"
                  className='enterZipField'
                  type="number"
                  placeholder="Zip code"
                  onChange={(e) => {
                    let zipString = e.currentTarget.value.toString()
                    if (zipString.length > 5) {
                      e.currentTarget.value = Number(zipString.substring(0, 5));
                    }
                    zipCode = e.currentTarget.value;
                  }}
                  variant="outlined" />
                <div/>
                <TextField item xs={6}
                  required
                  id="businessField"
                  className='enterBusinessField'
                  placeholder="Business name"
                  onChange={(e) => {
                    let businessString = e.currentTarget.value.toString()
                    if (businessString.length > 25) {
                      e.currentTarget.value = businessString.substring(0, 25);
                    }
                    businessName = e.currentTarget.value;
                  }}
                  variant="outlined" />
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12} className="alignCenter pt50">
            <Button onClick={() => startFlow()} variant="outlined" className={(newCallFormData.zipCode) ? "btn-eb5 width-full bordered next" : "btn-eb5 width-full bordered"}>
              Start
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default EnterZip;
