/**
 * Usage eg.
 * message:string - this will be show on alert box
 * 
 * import Loading from '../Components/AlertMessage';
 * 
 * <AlertMessage message="If you are a 5 Digital Energy client, reach out to us by chat or phone if you are not
sure when your current electricity contract expires"></AlertMessage>
 * 
 */
import React from 'react';
//import { Button } from '@mui/material';

const AlertMessage = (props) => {
    return (
        <div className='alert-message'>
            <div className='img-icon'>
                <img
                    src={`${process.env.PUBLIC_URL}/images/Alert.png`}
                    width="60"
                    height="60"
                    alt="Icon" />
            </div>
            <h2>{props.message}</h2>
        </div>
    )
}

export default AlertMessage
