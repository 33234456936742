import { createTheme } from '@mui/material/styles';

const energyBy5Theme = createTheme({
    palette: {
      secondary: {
        main: '#9e9e9e',
        contrastText: '#fff'
      },
      black: {
        main: '#1F1F1F',
        contrastText: '#fff'
      },
      blue: {
        main: '#0021F5',
        contrastText: '#fff'
      }
    }
});

export default energyBy5Theme;
