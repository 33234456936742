import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation ,useNewCallActions} from '../../../_actions';
import { newCallAtom, ESIDListAtom } from '../../../_state';
import {
  Container,
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  Autocomplete

} from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import UtilityAccountNumberReference from './ImageLightBox';
import dayjs from 'dayjs';
import { supplierListImages } from '../../../_common/app.constants';
import { IMaskInput } from 'react-imask';

const style = {
  width: '100%',
  margin: 'auto',
  marginBottom: '20px',
};
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const NewCallDetails = () => {
  const navigate = useNavigate();
  const [newCallData, setNewCall] = useRecoilState(newCallAtom);
  const validationSchema = Yup.object().shape({
    contactFirstName: Yup.string()
      .required("First Name is required")
      .max(20, 'First Name has a maximum limit of 20 characters.'),
    contactLastName: Yup.string()
      .required("Last Name is required")
      .max(20, 'Last Name has a maximum limit of 20 characters.'),
    contactEmail: Yup.string()
      .required("Email is required")
      .email('Email must be a valid email')
      .max(50, 'Email has a maximum limit of 50 characters.'),
    contactPhone: Yup.string()
      .trim()
      .required("Phone Number is required"),
    legalEntityName: Yup.string()
      .required("Legal Entity Name is required")
      .max(100, 'Legal Entity has a maximum limit of 100 characters.'),
    federalTaxId: Yup.string()
      .matches(/^(\d{9}|\d{11})$/, 'Invalid Federal Tax ID ')
      .required("Federal Tax ID is required"),
    serviceAddress: Yup.string()
      .required("Address is required")
      .max(255, 'Address has a maximum limit of 255 characters.'),
    serviceCity: Yup.string()
      .required("City is required")
      .max(20, 'City has a maximum limit of 20 characters.'),
    serviceState: Yup.string()
      .required("State is required")
      .max(20, 'State has a maximum limit of 20 characters.'),
    serviceZIP: Yup.string()
      .required("Zip is required")
      .max(5, 'Zip has a maximum limit of 5 characters.'),
    mailingAddress: Yup.string()
      .required("Address is required")
      .max(255, 'Address has a maximum limit of 255 characters.'),
    mailingCity: Yup.string()
      .required("City is required")
      .max(20, 'City has a maximum limit of 20 characters.'),
    mailingState: Yup.string()
      .required("State is required"),
    mailingZIP: Yup.string()
      .required("Zip is required")
      .max(5, 'Zip has a maximum limit of 5 characters.'),
    jobTitle: Yup.string()
      .required("Job Title Name is required")
      .max(50, 'Job Title has a maximum limit of 50 characters.'),
    monthlyUsage: Yup.number().
      positive()
      .min(1, "Must be more than 1 value")
      .max(84999, "Matrix pricing not available for this Monthly Usage"),
    textBox1:Yup.string()
      .required("This is required"),
    addMeter:Yup.array().of(
      Yup.object().shape({
        serviceAddress:Yup.string()
        .required("Address is required")
        .max(255, 'Address has a maximum limit of 255 characters.'),
        textBox1: Yup.string().required("This is required"),
        })
    ).required("This is required"),
  })
  //yup validation
  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const [mailingState, setMailingState] = useState(true)
  const [pageLoad, setPageLoad] = useState(false)
  const [inputFields, setInputFields] = useState([])
  const locationActions = useLocation();
  const esIdOptionList = useRecoilValue(ESIDListAtom);
  const newCallActions = useNewCallActions();
  let origin_prefix = origin.split('.')[0];
  let redirect_url = origin_prefix.includes('localhost') ? origin_prefix : origin_prefix + '.digitalenergyby5.com'

  const onSubmit = async() => {
    let fields = [];
    inputFields.forEach((field, index) => {
      fields[index] = field;
    });
    setNewCall((currentState) => ({
      ...currentState,
      inquiryServiceLocation: [...fields]
    })
    )
   await newCallActions.createNewCall();
   navigate('/review-docusign')
  }

  useEffect(() => {
    setNewCall({
      ...newCallData,
      mailingAddress: newCallData.serviceAddress,
      mailingCity: newCallData.serviceCity,
      mailingState: newCallData.serviceState,
      mailingZIP: newCallData.serviceZIP,
      utilityAccountNumber: newCallData.utilityAccountNumber,
      pricingId: newCallData.selectedPrice.id,
      textBoxLabel1: newCallData?.utilityIdObj?.utility_account_number?.textBox1,
      textBoxLabel2: newCallData?.utilityIdObj?.utility_account_number?.textBox2,
      textBoxLabel3: newCallData?.utilityIdObj?.utility_account_number?.textBox3
    });
    reset({
      ...newCallData,
      mailingAddress: newCallData.serviceAddress,
      mailingCity: newCallData.serviceCity,
      mailingState: newCallData.serviceState,
      mailingZIP: newCallData.serviceZIP,
      utilityAccountNumber: newCallData.utilityAccountNumber
    })
  }, [])

  useEffect(() => {
    reset({ ...newCallData,addMeter:[...inputFields] })
  }, [newCallData])

  const addFields = () => {
    let newfield = {
      serviceAddress: '',
      serviceCity: '',
      serviceState: '',
      serviceZIP: '',
      utilityAccountNumber: '',
      monthlyUsage: ''
    }
    setInputFields([...inputFields, newfield])
    reset({...newCallData,addMeter:[...inputFields, newfield]})
  }

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1)
    setInputFields(data)
    reset({...newCallData,addMeter:data})
  }

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name.split(".")[1]] = event.target.value;
    setInputFields(data);
  }
  const onEsidDataSetHandlerSingle = async (esIdData) => {
    let updateSetNewCall = {};
    if (esIdData) {
      const { _source } = esIdData;
      if (_source?.zipcode || '') {
        updateSetNewCall['serviceZIP'] = parseInt(String(_source?.zipcode).match(/\d{5}/g));
        updateSetNewCall['serviceState'] = _source.state;
        updateSetNewCall['serviceCity'] = _source.city;
        updateSetNewCall['serviceAddress'] = _source.address;
        updateSetNewCall['utilityAccountNumber'] = _source.esiid;
        updateSetNewCall['textBox1'] = _source.esiid;
        updateSetNewCall['duns'] = _source.duns;
        updateSetNewCall['premiseType'] = _source.premise_type;
        if (mailingState) {
          updateSetNewCall['mailingAddress'] = updateSetNewCall['serviceAddress'];
          updateSetNewCall['mailingCity'] = updateSetNewCall['serviceCity'];
          updateSetNewCall['mailingState'] = updateSetNewCall['serviceState'];
          updateSetNewCall['mailingZIP'] = updateSetNewCall['serviceZIP'];
        }
      }
      updateSetNewCall['ESID'] = esIdData
    } else {
      updateSetNewCall['serviceZIP'] = '';
      updateSetNewCall['serviceCity'] = '';
      updateSetNewCall['serviceAddress'] = '';
      updateSetNewCall['utilityAccountNumber'] = '';
      updateSetNewCall['textBox1'] = '';
      updateSetNewCall['duns'] = '';
      updateSetNewCall['premiseType'] = '';
      updateSetNewCall['ESID'] = ''
    }
    setNewCall({
      ...newCallData,
      ...updateSetNewCall
    })
  }


  const onEsidDataSetHandler = (esIdData, index) => {
    const { _source, label } = esIdData;
    let data = [...inputFields];
    if (_source?.zipcode || '') {
      data[index]['esiid'] = label;
      data[index]['serviceZIP'] = parseInt(String(_source?.zipcode).match(/\d{5}/g));
      data[index]['serviceState'] = _source.state;
      data[index]['serviceCity'] = _source.city;
      data[index]['serviceAddress'] = _source.address;
      data[index]['utilityAccountNumber'] = _source.esiid;
      data[index]['textBox1'] = _source.esiid;
      data[index]['duns'] = _source.duns;
    }
    setInputFields(data);
    reset({...newCallData,addMeter:data})
  }


  console.log(errors,'errors')

  return (
    <div className="search-compare new-call-details">
      <Container maxWidth='xl'>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h2" component="h2" align="center" sx={{ mb: 3 }}>
            Please Enter Your Information
          </Typography>
          <Grid container rowSpacing={2} columnSpacing={{ sm: 2, md: 3, lg: 4, xl: 5 }}>
            <Grid item alignItems={'flex-start'} container xs={12} sm={12} md={8} rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5" sx={{ mb: 1 }}>
                  Contact & Company Information
                </Typography>
              </Grid>
              {/* Table M section */}
              <Grid item container xs={12} sm={6} rowSpacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="First Name"
                    value={newCallData?.contactFirstName || ''}
                    onChange={e => setNewCall({ ...newCallData, 'contactFirstName': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactFirstName?.message}</div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="Last Name"
                    value={newCallData?.contactLastName || ''}
                    onChange={e => setNewCall({ ...newCallData, 'contactLastName': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactLastName?.message}</div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="email"
                    fullWidth
                    required
                    size="small"
                    label="Email Address"
                    value={newCallData?.contactEmail || ''}
                    onChange={e => setNewCall({ ...newCallData, 'contactEmail': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactEmail?.message}</div>

                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Phone Number"
                    type="phone"
                    id="formatted-numberformat-input"
                    size='small'
                    className={`form-control ${errors.contactPhone ? 'is-invalid' : ''}`}
                    value={newCallData?.contactPhone || ''}
                    onChange={e => setNewCall({ ...newCallData, contactPhone: e.target.value })}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactPhone?.message}</div>
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    required
                    label="Job Title"
                    fullWidth
                    autoComplete="off"
                    margin="none"
                    size="small"
                    value={newCallData?.jobTitle || ''}
                    onChange={e => setNewCall({ ...newCallData, 'jobTitle': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.jobTitle?.message}</div>
                </Grid>

              </Grid>
              <Grid item container rowSpacing={2} columnSpacing={2} xs={12} sm={6}>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Legal Entity Name"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    margin="none"
                    value={newCallData?.legalEntityName || ''}
                    onChange={e => setNewCall({ ...newCallData, 'legalEntityName': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.legalEntityName?.message}</div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="Federal Tax ID (EIN)"
                    value={newCallData?.federalTaxId || ''}
                    onChange={e => setNewCall({ ...newCallData, 'federalTaxId': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.federalTaxId?.message}</div>
                </Grid>
                <Grid item xs={12}>
                  <Box component="div" className='radio-groups' sx={{ mt: { lg: '5px', md: 0, } }}>
                    <div className="form-group">
                      <input type="checkbox" checked={mailingState} id="mailing_address" name="mailing_address" value="1"
                        onChange={e => {
                          setMailingState(e.target.checked);
                          if (e.target.checked) {
                            setNewCall({
                              ...newCallData,
                              'mailingAddress': newCallData.serviceAddress,
                              'mailingCity': newCallData.serviceCity,
                              'mailingState': newCallData.serviceState,
                              'mailingZIP': newCallData.serviceZIP
                            })
                          }
                        }} />
                      <label for="mailing_address">Mailing address is the same as service location</label>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    // disabled={mailingState}                
                    label="Mailing Address"
                    fullWidth
                    autoComplete="off"
                    margin="none"
                    size="small"
                    value={newCallData?.mailingAddress || ''}
                    onChange={e => setNewCall({ ...newCallData, 'mailingAddress': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.mailingAddress?.message}</div>
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                  <TextField
                    required
                    disabled={mailingState}
                    label="City"
                    fullWidth
                    size="small"
                    margin="none"
                    value={newCallData?.mailingCity || ''}
                    onChange={e => setNewCall({ ...newCallData, 'mailingCity': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.mailingCity?.message}</div>
                </Grid>
                <Grid item xs={12} sm={2} md={4}>
                  <TextField
                    required
                    disabled={mailingState}
                    label="State"
                    autoComplete="off"
                    margin="none"
                    size="small"
                    fullWidth
                    value={newCallData?.mailingState || ''}
                    onChange={e => setNewCall({ ...newCallData, 'mailingState': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.mailingState?.message}</div>

                </Grid>
                <Grid item xs={12} sm={5} md={3}>
                  <TextField
                    required
                    disabled={mailingState}
                    label="Zip Code"
                    fullWidth
                    autoComplete="off"
                    margin="none"
                    size="small"
                    value={newCallData?.mailingZIP || ''}
                    onChange={e => setNewCall({ ...newCallData, 'mailingZIP': e.target.value })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.mailingZIP?.message}</div>

                </Grid>

              </Grid>
              <Grid item container xs={12} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h5" sx={{ mb: 1 }}>
                    Service Account Information
                  </Typography>
                </Grid>
                {newCallData?.serviceState === 'TX' &&
                  <Grid item xs={12} className="not-btn-style not-bullet">
                    <Autocomplete
                      noOptionsText={"Not found, continue without address"}
                      disablePortal
                      fullWidth
                      id="combo-box-demo"
                      size="small"
                      value={newCallData?.ESID?.label || ''}
                      onInputChange={(e) => locationActions.esidLookup(e?.target?.value)}
                      options={esIdOptionList.map((option) => ({
                        ...option,
                        label: `${option._source.address.trim()},${option._source.city.trim()},${option._source.state.trim()} ${parseInt(String(option._source.zipcode).match(/\d{5}/g))} | ${option._source.esiid.trim()}`
                      }))
                      }
                      onChange={(e, props) => onEsidDataSetHandlerSingle(props)
                      }
                      renderInput={(params) => <TextField  {...params} label="Search street address or ESID" />}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    fullWidth
                    required
                    size="small"
                    label="Service Address "
                    name={`serviceAddress`}
                    value={newCallData?.serviceAddress || ''}
                    onChange={e => setNewCall({
                      ...newCallData,
                      'serviceAddress': e.target.value,
                      mailingAddress: (mailingState) ? e.target.value : newCallData.mailingAddress
                    })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.serviceAddress?.message}</div>

                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    type="text"
                    fullWidth
                    required
                    size="small"
                    label="City"
                    name={`serviceCity`}
                    value={newCallData?.serviceCity || ''}
                    onChange={e => setNewCall({
                      ...newCallData,
                      'serviceCity': e.target.value,
                      mailingCity: (mailingState) ? e.target.value : newCallData.mailingCity
                    })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.serviceCity?.message}</div>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    type="text"
                    fullWidth
                    required
                    size="small"
                    label="State"
                    name={`serviceState`}
                    value={newCallData?.serviceState || ''}
                    onChange={e => setNewCall({
                      ...newCallData,
                      'serviceState': e.target.value,
                      mailingState: (mailingState) ? e.target.value : newCallData.mailingState
                    })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.serviceState?.message}</div>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    type="text"
                    fullWidth
                    required
                    size="small"
                    label="Zip"
                    name={`serviceZIP`}
                    value={newCallData?.serviceZIP || ''}
                    onChange={e => setNewCall({
                      ...newCallData,
                      'serviceZIP': e.target.value,
                      mailingZIP: (mailingState) ? e.target.value : newCallData.mailingZIP
                    })}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.serviceZIP?.message}</div>

                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Monthly Usage (kwh)"
                    fullWidth
                    autoComplete="off"
                    margin="none"
                    size="small"
                    name={`monthlyUsage`}
                    value={newCallData?.monthlyUsage || ''}
                    onChange={(e) => {
                      setNewCall({
                        ...newCallData,
                        monthlyUsage: e.target.value,
                        annualUsage: (e.target.value * 0.012).toFixed(2),
                        annualValue: ((newCallData.margin) * (e.target.value * 0.012)).toFixed(2),
                        dealValue: ((e.target.value * 0.012) * newCallData?.margin).toFixed(2),
                      })
                    }}
                  />
                  <div className="invalid-feedback" style={{ color: "red" }}>{errors.monthlyUsage?.message}</div>
                </Grid>

                {newCallData?.utilityIdObj?.utility_account_number?.textBox1 &&
                  <Grid item xs={12} sm={6} className="p-relative">
                    <TextField
                      required
                      label={newCallData?.utilityIdObj?.utility_account_number?.textBox1}
                      fullWidth
                      autoComplete="off"
                      margin="none"
                      size="small"
                      name={`textBox1`}
                      value={newCallData?.textBox1 || ''}
                      onChange={e => setNewCall({ ...newCallData, 'textBox1': e.target.value, textBoxLabel1: newCallData?.utilityIdObj?.utility_account_number?.textBox1 })}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.textBox1?.message}</div>
                    <UtilityAccountNumberReference />
                  </Grid>
                }
                {newCallData?.utilityIdObj?.utility_account_number?.textBox2 &&
                  <Grid item xs={12} sm={6} className="p-relative">
                    <TextField
                      required
                      label={newCallData?.utilityIdObj?.utility_account_number?.textBox2}
                      fullWidth
                      autoComplete="off"
                      margin="none"
                      size="small"
                      name={`textBox2`}
                      value={newCallData?.textBox2 || ''}
                      onChange={e => setNewCall({ ...newCallData, 'textBox2': e.target.value, textBoxLabel2: newCallData?.utilityIdObj?.utility_account_number?.textBox2 })}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.textBox2?.message}</div>
                    <UtilityAccountNumberReference />
                  </Grid>
                }
                {newCallData?.utilityIdObj?.utility_account_number?.textBox3 &&
                  <Grid item xs={12} sm={6} className="p-relative">
                    <TextField
                      required
                      label={newCallData?.utilityIdObj?.utility_account_number?.textBox3}
                      fullWidth
                      autoComplete="off"
                      margin="none"
                      size="small"
                      name={`textBox3`}
                      value={newCallData?.textBox3 || ''}
                      onChange={e => setNewCall({ ...newCallData, 'textBox3': e.target.value, textBoxLabel3: newCallData?.utilityIdObj?.utility_account_number?.textBox3 })}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.textBox3?.message}</div>
                    <UtilityAccountNumberReference />
                  </Grid>
                }

              </Grid>
              <Grid item container spacing={2} xs={12}>
                {inputFields?.map((input, index) => {
                  return (
                    <Grid key={index} item container spacing={2} xs={12} className='account-added-row'>
                      {newCallData.serviceState === 'TX' &&
                        <Grid item xs={12} sm={12} className='not-btn-style'>
                          <Autocomplete
                            noOptionsText={"Not found, continue without address"}
                            freeSolo
                            disablePortal
                            disabled={((newCallData.serviceState === 'TX') ? false : true)}
                            fullWidth
                            id="combo-box-demo"
                            size="small"
                            value={input?.label || ''}
                            onInputChange={(e) => locationActions.esidLookup(e?.target?.value)}
                            options={esIdOptionList.map((option) => ({
                              ...option,
                              label: `${option._source.address},${option._source.city},${option._source.state} ${parseInt(String(option._source.zipcode).match(/\d{5}/g))}|${option._source.esiid}`
                            }))
                            }
                            onChange={(e, props) => onEsidDataSetHandler(props, index)
                            }
                            renderInput={(params) => <TextField required {...params} label="Street address or ESID" />}
                          />
                          <div className="invalid-feedback" style={{ color: "red" }}>{errors.ESID?.message}</div>
                        </Grid>
                      }
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          label="Service Location"
                          fullWidth
                          autoComplete="off"
                          margin="none"
                          size="small"
                          name={`addMeter[${index}].serviceAddress`}
                          value={input?.serviceAddress || ''}
                          onChange={event => handleFormChange(index, event)}
                        />
                        {errors.addMeter && errors.addMeter[index] &&
                          <div className="invalid-feedback" style={{ color: "red" }}>{errors.addMeter[index]?.serviceAddress?.message}</div>
                        }
                        </Grid>
                      <Grid item xs={12} sm={7}>
                        <TextField
                          label="City"
                          fullWidth
                          autoComplete="off"
                          margin="none"
                          size="small"
                          name={`addMeter[${index}].serviceCity`}

                          value={input?.serviceCity || ''}
                          onChange={event => handleFormChange(index, event)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="State"
                          autoComplete="off"
                          margin="none"
                          size="small"
                          fullWidth
                          name={`addMeter[${index}].serviceState`}
                          value={input?.serviceState || ''}
                          onChange={event => handleFormChange(index, event)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} >
                        <TextField
                          label="Zip Code"
                          fullWidth
                          autoComplete="off"
                          margin="none"
                          size="small"

                          name={`addMeter[${index}].serviceZIP`}
                          value={input?.serviceZIP || ''}
                          onChange={event => handleFormChange(index, event)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Monthly Usage (kwh)"
                          fullWidth
                          autoComplete="off"
                          margin="none"
                          size="small"
                          name={`addMeter[${index}].monthlyUsage`}
                          value={input?.monthlyUsage || ''}
                          onChange={event => handleFormChange(index, event)}
                        />
                      </Grid>
                      {newCallData?.utilityIdObj?.utility_account_number?.textBox1 &&
                        <Grid item xs={12} sm={6} md={4} className="p-relative">
                          <TextField
                            required
                            label={newCallData?.utilityIdObj?.utility_account_number?.textBox1}
                            fullWidth
                            autoComplete="off"
                            margin="none"
                            size="small"
                            name={`addMeter[${index}].textBox1`}
                            value={input?.textBox1 || ''}
                            onChange={event => handleFormChange(index, event)}
                          />
                          <UtilityAccountNumberReference />
                          {errors.addMeter && errors.addMeter[index] &&
                            <div className="invalid-feedback" style={{ color: "red" }}>{errors.addMeter[index]?.textBox1?.message}</div>
                          }
                          </Grid>
                      }
                      {newCallData?.utilityIdObj?.utility_account_number?.textBox2 &&
                        <Grid item xs={12} sm={6} md={5} className="p-relative">
                          <TextField
                            label={newCallData?.utilityIdObj?.utility_account_number?.textBox2}
                            fullWidth
                            autoComplete="off"
                            margin="none"
                            size="small"
                            name={`addMeter[${index}].textBox2`}
                            value={input?.textBox2 || ''}
                            onChange={event => handleFormChange(index, event)}
                          />
                          <UtilityAccountNumberReference />
                        </Grid>
                      }
                      {newCallData?.utilityIdObj?.utility_account_number?.textBox3 &&
                        <Grid item xs={12} sm={6} md={5} className="p-relative">
                          <TextField
                            label={newCallData?.utilityIdObj?.utility_account_number?.textBox3}
                            fullWidth
                            autoComplete="off"
                            margin="none"
                            size="small"
                            name={`addMeter[${index}].textBox3`}
                            value={input?.textBox3 || ''}
                            onChange={event => handleFormChange(index, event)}
                          />
                          <UtilityAccountNumberReference />
                        </Grid>
                      }
                      <Grid item xs={12} sm={6} md={2}>
                        <Button
                          onClick={() => removeFields(index)}
                          variant="contained"
                          color="secondary"
                          className="btn-secondary">
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Box component="div" sx={{ mt: { md: 0, sm: 5 }, marginBottom: 3, border: '1px solid #b5b5b5' }}>
                <div className="box-header" style={{ padding: '23px 20px', borderColor: '#0000ff', borderBottom: '5px solid #0000ff', color: '#0000ff' }}>
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    {supplierListImages[newCallData?.selectedSupplier?.name]?.image !== '' ?
                      <img className='supplier_logo' alt="ClientLogo" src={`${process.env.PUBLIC_URL}/images/client-logos/${supplierListImages[newCallData?.selectedSupplier?.name]?.image}`} />
                      : ''
                    }
                  </div>
                  <Typography
                    component="h6"
                    variant="h6"
                    color="text.primary"
                    sx={{ color: '#0000ff', fontSize: '16px', fontWeight: 600 }}
                  >
                    {newCallData?.selectedSupplier?.name} - <span>{newCallData?.selectedPrice?.term}</span>
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    color="text.primary"
                    sx={{ color: '#0000ff', fontSize: '16px', fontWeight: 600 }}
                  >
                    Rate ({newCallData?.rate} ₵/kWh)
                  </Typography>
                </div>
                <div className="box-body" style={{ padding: '20px' }}>
                  <div className="list-itm"> Start Date: <span>{dayjs(newCallData?.requestedStartDate || '').format((newCallData.monthlyLabel === 'Estimated Square Footage') ? 'MM/YYYY' : 'MM/DD/YYYY')}</span></div>
                  <div className="list-itm"> Contract Term: <span>{newCallData?.selectedPrice?.term} Months</span></div>
                  <div className="list-itm"> Utility: <span>{newCallData.utilityIdObj.label}</span></div>
                  <div className="list-itm"> Estimated Annual Usage: <span>{newCallData.annualUsage*1000} kWh</span></div>
                  <div className="list-itm"> Enrollment Type: <span>{newCallData.enrollmentType}</span></div>
                  <div className="list-itm"> Early Termination Fee:
                    <span>
                      <Button className="btn-link" onClick={e => window.open(
                        redirect_url + '/resources/',
                        '_blank'
                      )} > Varies </Button>
                    </span>
                  </div>
                </div>
              </Box>
              <Grid item xs={12} align="center">
                <div>
                  <Button sx={{ width: '100%', maxWidth: '200px', m: 1 }} onClick={addFields} className="btn-outline-primary" variant="outlined" startIcon={<AddIcon />}>Add accounts</Button>
                </div>
                <div>
                  <Button size='large' variant="contained" color='primary' className='btn-primary' sx={{ width: '100%', maxWidth: '200px', m: 1 }} onClick={handleSubmit(onSubmit)} >Review Contract</Button>
                </div>
                <div>
                  <Button onClick={e => navigate(-1)} sx={{ width: '100%', maxWidth: '200px', m: 1 }} variant="contained" color='primary' size='large' className='btn-primary'>Go Back</Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div >
  )
}

export default NewCallDetails;
