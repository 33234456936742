import { React, useEffect, useState } from 'react'
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  Grid, Container, Button, FormControl, TextField, FormGroup,
  FormControlLabel, Checkbox, Card, CardHeader, CardActions,
  CardContent, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Backdrop, CircularProgress,
  Modal, Box, Link, Select, MenuItem
} from '@mui/material';
import { useUploadPriceActions } from '../../../_actions';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useNavigate } from "react-router-dom";
import { newCallAtom, masterAtom, priceMatrixSuccessAtom } from '../../../_state';
import { supplierListImages } from '../../../_common/app.constants';
import { ThemeProvider } from '@mui/material/styles';
import energyBy5Theme from '../../../muiColorTheme.js';
import Steps from '../Components/Steps';
import Loading from '../Components/Loading';
import '../custom.css';

const getBudgetTablesSrc = (supplierName, pricingRateRanges, offerRate, annualKwh, annualUsage) => {
  const annualUsageValue = parseInt(annualUsage) * 1000;
  const annualKwhValue = annualKwh === 0 ? annualUsageValue : annualKwh;
  const calculationEnergyChargeFormula = `${offerRate}¢/kWh x ${Math.ceil(Number(annualKwhValue)).toLocaleString()}kWh`;
  const calculationEnergyCharge = ((offerRate / 100) * annualKwhValue);
  const totalEnergyCharge = `$${Math.ceil(Number(calculationEnergyCharge)).toLocaleString()}`;
  const { lowRate, highRate } = pricingRateRanges;
  const deliveryChargeLowRate = lowRate ? (lowRate * annualKwhValue) : null;
  const deliveryChargeHighRate = highRate ? (highRate * annualKwhValue) : null;
  const calculationDeliveryChargeFormula = (deliveryChargeLowRate && deliveryChargeHighRate) ?
    `$${Math.ceil(Number(deliveryChargeLowRate)).toLocaleString()} to $${Math.ceil(Number(deliveryChargeHighRate)).toLocaleString()}` : null;
  const totalBudgetLowRate = deliveryChargeLowRate ? (calculationEnergyCharge + deliveryChargeLowRate) : null;
  const totalBudgetHighRate = deliveryChargeHighRate ? (calculationEnergyCharge + deliveryChargeHighRate) : null;
  const calculationBudgetEstimate = (deliveryChargeLowRate && deliveryChargeHighRate) ?
    `$${Math.ceil(Number(totalBudgetLowRate)).toLocaleString()} to $${Math.ceil(Number(totalBudgetHighRate)).toLocaleString()}` : null;

  const tableBudgetEstimate = {
    title: 'Budget Estimate:',
    totalValue: calculationBudgetEstimate,
    notes: `This is an estimate of your electricity supply charges from the selected supplier and delivery costs from your local utility for budgeting purposes. Your actual costs will depend on the actual amount of electricity used over the course of the year and subject to any tariff changes made by the utility. This does not included taxes.`
  };
  const tableElectricitySupplyCharges = {
    title: `Electricity Supply Charges`,
    shortName: 'Energy Charge',
    totalValue: totalEnergyCharge,
    calculation: calculationEnergyChargeFormula,
    notes: 'This is an estimate of your electricity supply charges from the selected supplier for budgeting purposes. Your actual costs will depend on the actual amount of electricity used over the course of the year and does not include taxes.',
  };
  const regulatedDeliveryCharges = {
    title: 'Regulated delivery charges from your utility.\nThese charges represent the cost of delivering electricity to your facility and are established through tariffs set by each utility company.',
    totalValue: calculationDeliveryChargeFormula,
    longName: 'This is an estimate of your annual delivery charges for budgeting purposes. Actual delivery charges are based on your annual usage and any tariff changes implemented by your utility.'
  };

  return { tableBudgetEstimate, tableElectricitySupplyCharges, regulatedDeliveryCharges };
};

const parseExtraBestOfferData = (bestOffer, detailsExtraInformation, annualKwh, annualUsage) => {
  let offerParsed = { ...bestOffer };
  const { extraPriceInformation, pricingRateRanges } = detailsExtraInformation || [];
  const extraInformationPrice = extraPriceInformation.find((extraInfoItem) => extraInfoItem.supplierId === offerParsed.selectedSupplier.id);
  const supplierName = bestOffer.supplier || 'selected supplier';
  const mockUpData = {
    supplierBio: `Some contract details and a budget summary associated with the offer from ${supplierName} are below`
  };

  if (extraInformationPrice) {
    const { fixedRate, paymentTerms, contractCancellationAllowances, contractCancellationPenalties } = extraInformationPrice.pricingDetails;

    offerParsed.offerPerks = {
      fixedRate: fixedRate || '-',
      paymentTerms: paymentTerms || '-',
      contractCancellationAllowances: contractCancellationAllowances ? contractCancellationAllowances : '-',
      contractCancellationPenalties: contractCancellationPenalties ? contractCancellationPenalties : 'No cancellation policy available from this supplier.',
      greenEnergy: offerParsed.greenEnergy === 'Yes' ? true : '-'
    };

    const budgetSummary = { ...extraInformationPrice.infoBubble };
    budgetSummary.budgetTables = getBudgetTablesSrc(offerParsed.supplier, pricingRateRanges, offerParsed.rate, annualKwh, annualUsage);
    offerParsed.budgetSummary = budgetSummary || null;
    offerParsed.supplierBio = mockUpData.supplierBio || 'No description available'; // we need this data.
  }

  return (offerParsed);
}

const offerCard = (bestOffer, indexBestOffer, triggerCompareBannerCallback, handleSelectPlanCallback, triggerOfferCompareModalCallback, showDetailsOptions) => {
  let cardTitle = '';
  const optionClassName = `option-${indexBestOffer + 1}`;
  switch (indexBestOffer) {
    case 0:
      cardTitle = 'Our Top Recommendation';
      break;
    default:
      cardTitle = 'Other Recommendation';
      break;
  }
  if (bestOffer.userSelection) {
    cardTitle = 'Your Selection'
  }
  return (
    <Card variant="outlined" className="card-eb5">
      <CardHeader title={cardTitle} className={`card-title ${optionClassName}`} />
      <CardContent>
        <div className="card-body">
          <div className="offer-main-data">
            {getSupplierLogo(bestOffer.supplier)}
            <div className="offer-card-price">
              <span>{bestOffer.supplierPrice}</span>
              <span> ¢/kWh</span>
            </div>
          </div>
          <div className="offer-extra-data">
            <span className="offer-time">{bestOffer.supplierTerm}</span>
            {showDetailsOptions &&
              <Link onClick={() => { triggerOfferCompareModalCallback(bestOffer) }} underline="none">View Details</Link>
            }
          </div>
        </div>
      </CardContent>
      <CardActions className="card-actions-container">
        <Button className='compare-button' size="small" onClick={triggerCompareBannerCallback}>Compare</Button>
        <ThemeProvider theme={energyBy5Theme}>
          <Button
            size="small" variant="contained" color="blue"
            className="btn-eb5 white-txt next"
            onClick={() => { handleSelectPlanCallback(bestOffer) }}>
            Select Plan
          </Button>
        </ThemeProvider>
      </CardActions>
    </Card>
  )
};

const offerCardModal = (bestOffer, indexBestOffer, handleSelectPlanCallback, detailsExtraInformation, annualKwh, triggerShowPriceDetailsDescriptionCallback, showDetailsOptions) => {
  let cardTitle = '';
  const optionClassName = `option-${indexBestOffer + 1}`;
  switch (indexBestOffer) {
    case 0:
      cardTitle = 'Our Top Recommendation';
      break;
    default:
      cardTitle = 'Other Recommendation';
      break;
  }

  const bestOfferParsed = parseExtraBestOfferData(bestOffer, detailsExtraInformation, annualKwh);

  const linkEventHandler = (bestOfferSelected) => {
    triggerShowPriceDetailsDescriptionCallback(bestOfferSelected);
  }

  return (
    <Card variant="outlined" className="card-eb5">
      <CardHeader title={cardTitle} className={`card-title ${optionClassName}`} />
      <CardContent>
        <div className="card-body">
          <div className="offer-main-data">
            <div className="offer-price">
              <span>{bestOfferParsed.supplierPrice} ¢/kWh</span>
            </div>
            {getSupplierLogo(bestOfferParsed.supplier)}
            <span className="offer-time">{bestOfferParsed.supplierTerm}</span>
            <span className="offer-rate">{bestOfferParsed.offerPerks?.fixedRate}</span>
            <span className="offer-rate">{bestOfferParsed.offerPerks?.contractCancellationAllowances}</span>
            <span className="offer-rate">{bestOfferParsed.offerPerks?.contractCancellationPenalties}</span>
            <span className="offer-rate">{bestOfferParsed.greenEnergy}</span>
            <span className="offer-rate">{bestOfferParsed.offerPerks?.paymentTerms}</span>
            {showDetailsOptions &&
              <span className="offer-rate">
                <Link onClick={() => { linkEventHandler(bestOfferParsed) }} underline="none">View Details</Link>
              </span>
            }
          </div>
        </div>
      </CardContent>
      <CardActions className="card-actions-container">
        <ThemeProvider theme={energyBy5Theme}>
          <Button
            size="small" variant="contained" color="black"
            className="btn-eb5 white-txt"
            onClick={() => { handleSelectPlanCallback(bestOfferParsed) }}>
            Select Plan
          </Button>
        </ThemeProvider>
      </CardActions>
    </Card>
  )
}

const smallCards = (offerItem, removeElementCallback, index) => {
  return (
    <div className="small-card">
      <div className="small-card-container">
        <span className="close-btn" onClick={() => { removeElementCallback(index) }}>&times;</span>
        <div className="small-card-body">
          {getSupplierLogo(offerItem.supplier)}
          <div className="small-card-price">
            <span>{offerItem.supplierPrice}</span>
            <span> ¢/kWh</span>
          </div>
        </div>
      </div>
    </div>
  )
};

const getSupplierLogo = (supplier) => {
  if (supplierListImages[supplier] && supplierListImages[supplier]['image'] !== '') {
    return (<img className='supplier-logo' alt="Supplier logo" src={`${process.env.PUBLIC_URL}/images/client-logos/new-logos/${supplierListImages[supplier]['logoId']}_card_logo.png`} />);
  }
  return supplier;
};

const getMarker = (offerPerk, offerPerksList) => {
  const imgSrc = `${process.env.PUBLIC_URL}/images/`;
  if (offerPerksList[offerPerk]) {
    if (offerPerksList[offerPerk] !== '-') {
      return (imgSrc + 'bulletCheck.png');
    }
  }
  return (imgSrc + 'bulletCross.png');
};

const getDetailsTableSummary = (tableData, kindOfTable) => {
  const { title, totalValue, shortName, longName, notes, calculation } = tableData;
  const dataTableParser = () => {
    let tableDataSrc = {};
    if (kindOfTable === 'simple') {
      tableDataSrc = {
        tableHead: {
          title: title,
          value: `${totalValue} per year`
        },
        tableFoot: {
          title: notes
        }
      };
    } else {
      let rowsSrc = [
        {
          title: shortName || '',
          description: calculation || '',
          value: `${totalValue} per year` || ''
        },
        {
          title: '',
          description: notes,
          value: '',
          doble: true,
        }
      ];

      if (longName) {
        rowsSrc = [
          {
            title: longName,
            description: calculation || '',
            value: `${totalValue} per year` || ''
          },
        ];
      }

      tableDataSrc = {
        tableHead: {
          title: title,
          value: totalValue
        },
        tableBody: {
          rows: rowsSrc
        },
        tableFoot: {
          title: 'Subtotal:',
          value: totalValue
        }
      };
    }

    return (tableDataSrc);
  };

  const dataTableParsed = dataTableParser(tableData);

  if (kindOfTable === 'simple') {
    return (
      <table className="price-details-table">
        <thead>
          <tr>
            <th scope="col" colSpan="2" className="txt-align-left">{dataTableParsed.tableHead.title}</th>
            <th scope="col" className="txt-align-right">{dataTableParsed.tableHead.value}</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td colSpan="3" className="txt-align-left">{dataTableParsed.tableFoot.title}</td>
          </tr>
        </tfoot>
      </table>
    );
  }

  return (
    <table className="price-details-table">
      <thead>
        <tr>
          <th scope="col" colSpan="3" className="txt-align-left display-linebreak">{dataTableParsed.tableHead.title}</th>
        </tr>
      </thead>
      <tbody>
        {dataTableParsed.tableBody.rows.map(rowItem => {
          if (!rowItem.doble) {
            return (
              <tr>
                <th scope="row" className="txt-align-left">{rowItem.title}</th>
                <td className="display-linebreak">{rowItem.description}</td>
                <td className="txt-align-right flex-align-right">{rowItem.value}</td>
              </tr>
            )
          } else {
            return (
              <tr>
                <td colSpan="2" className="display-linebreak">{rowItem.description}</td>
                <td className="txt-align-right flex-align-right">{rowItem.value}</td>
              </tr>
            )
          }
        })}
      </tbody>
      <tfoot>
        <tr>
          <th scope="row" colSpan="2" className="font-bold">{dataTableParsed.tableFoot.title}</th>
          <td className="txt-align-right">{dataTableParsed.tableFoot.value}</td>
        </tr>
      </tfoot>
    </table>
  );
}

const getSupplierListSorted = (suppliersList) => {
  let supplierSorted = [...suppliersList];
  const staticSuppliersList = ['ENGIE', 'NRG', 'APG&E', 'CONSTELLATION', 'GREEN MOUNTAIN'];
  const sortAccording = (A1, A2) => {
    // Map to store the indices of elements in the second array
    let index = new Map();
    for (let i = 0; i < A2.length; i++) {
      // Consider only first occurrence of element
      if (!index.has(A2[i])) {
        // Assign value of i+1
        index.set(A2[i], i + 1);
      }
    }
    A1.sort((a, b) => {
      let idx1 = index.get(a.toUpperCase()) || 0;
      let idx2 = index.get(b.toUpperCase()) || 0;
      // If both a and b are not present in the second array, sort them in ascending order
      if (idx1 === 0 && idx2 === 0) { return a - b; }
      // If only b is present in the second array, b comes before a
      if (idx1 === 0) { return 1; }
      // If only a is present in the second array, a comes before b
      if (idx2 === 0) { return -1; }
      // If both a and b are present in the second array, sort them according to their respective indices
      return idx1 - idx2;
    });
  }
  sortAccording(supplierSorted, staticSuppliersList);
  return (supplierSorted);
};

const getTablePricesData = (termsList, suppliersListSorted, priceMatrix, masterSetting, annualUsage) => {
  const supplierPricesSorted = [];
  termsList.forEach((termItem) => {
    const rowPrice = [];
    suppliersListSorted.forEach((supplierItem, supplierIndex) => {
      if (supplierIndex === 0) {
        const termRow = termItem === 1 ? 'Monthly' : `${termItem} Months`;
        rowPrice.push({ term: termRow });
      }

      if (priceMatrix[termItem]) {
        if (priceMatrix[termItem].hasOwnProperty(supplierItem)) {
          const supplierOfferByTerm = priceMatrix[termItem][supplierItem];
          const supplierTerm = rowPrice[0].term;
          const marginTax = masterSetting?.marginTerms === 'no' ? masterSetting['margin'] : masterSetting[`margin${termItem}m`];
          const supplierPrice = ((supplierOfferByTerm.price * 100) + (marginTax / 10)).toFixed(2);
          const supplierAnnualUsage = marginTax * parseFloat(annualUsage);
          const dealValue = ((supplierAnnualUsage) * (termItem / 12)).toFixed(2);
          const greenEnergy = supplierOfferByTerm.green === 1 ? 'Yes' : 'No';
          rowPrice.push({
            supplier: supplierItem,
            supplierPrice,
            bestOffer: '',
            supplierTerm,
            pricingId: supplierOfferByTerm.id,
            selectedSupplier: { ...supplierOfferByTerm.supplierId },
            margin: marginTax,
            dealValue,
            annualValue: supplierAnnualUsage.toFixed(2),
            rate: supplierPrice,
            state: supplierOfferByTerm.state,
            greenEnergy
          });
        } else {
          rowPrice.push({ supplier: supplierItem, supplierPrice: '-' });
        }
      } else {
        rowPrice.push({ supplier: supplierItem, supplierPrice: '-' });
      }
    });
    supplierPricesSorted.push(rowPrice);
  });

  return (supplierPricesSorted);
};

const getPriceFormatted = (price) => {
  if (price) {
    if (price.term) { return (price.term); }
    if (price.supplierPrice) {
      if ((!isNaN(parseFloat(price.supplierPrice)))) {
        return (<>{price.supplierPrice} <span className='small'>₵/kWh</span></>);
      }
    }
    return (<>{price.supplierPrice}</>);
  }
};

const setBestOffersList = (dataPriceTable, termLength, termsList) => {
  const bestOffers = [];
  let moreThan3Offers = [];
  for (const priceItem of dataPriceTable) {
    if (priceItem[0] !== undefined) {
      priceItem.forEach((price) => {
        if (!isNaN(price.supplierPrice) && (price.supplierPrice.indexOf('-') < 0)) {
          if (bestOffers.length < 3) {
            price.bestOffer = 1;
            bestOffers.push(price);
          } else {
            bestOffers.sort((a, b) => { return a.supplierPrice - b.supplierPrice }).forEach((offer, index) => { offer.bestOffer = index + 1 });
            if (
              parseFloat(price.supplierPrice) < parseFloat(bestOffers[0].supplierPrice) ||
              parseFloat(price.supplierPrice) < parseFloat(bestOffers[1].supplierPrice) ||
              parseFloat(price.supplierPrice) < parseFloat(bestOffers[2].supplierPrice)
            ) {
              if (moreThan3Offers.length <= 0) {
                bestOffers.forEach((priceOffer) => { moreThan3Offers.push(priceOffer) });
              }
              moreThan3Offers.push(price);
            }
          }
        }
      });
    }
  }

  if (moreThan3Offers.length > 0) {
    moreThan3Offers.sort((a, b) => { return a.supplierPrice - b.supplierPrice }).forEach((offer, index) => { offer.bestOffer = index + 1 });
    bestOffers[0] = moreThan3Offers[0];
    bestOffers[1] = moreThan3Offers[1];
    bestOffers[2] = moreThan3Offers[2];
  } else if (moreThan3Offers.length === 0) {
    if (dataPriceTable[0].length === 2 && dataPriceTable[0][1].rate) {
      let defaultOffer = dataPriceTable[0][1];
      defaultOffer.bestOffer = 1;
      bestOffers[0] = dataPriceTable[0][1];
    }
  } else {
    bestOffers.sort((a, b) => { return a.supplierPrice - b.supplierPrice }).forEach((offer, index) => { offer.bestOffer = index + 1 });
  }

  return bestOffers;
}

const GetRates = () => {
  const navigate = useNavigate();
  const priceActions = useUploadPriceActions();
  const priceMatrixSuccess = useRecoilValue(priceMatrixSuccessAtom);
  const { masterSetting } = useRecoilValue(masterAtom);
  const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);

  const [showCompareBanner, setShowCompareBanner] = useState(false);
  const [openOfferCompareModal, setOpenOfferCompareModal] = useState(false);
  const [showPriceDetailsDescription, setPriceDetailsDescription] = useState(false);

  const [bannerOfferList, setBannerOfferList] = useState([]);
  const [best3OffersList, setBest3OffersList] = useState([]);
  const [tableOffers, setTableOffers] = useState([]);
  const [termListSorted, setTermListSorted] = useState([]);
  const [suppliersListSorted, setSuppliersList] = useState([]);
  const [offerDetailsDescriptionSelected, setOfferDetailsDescriptionSelected] = useState({ offerSelected: {}, directAccess: false });

  const [detailsExtraInformation, setDetailsExtraInformation] = useState({ extraPriceInformation: [], pricingRateRanges: {} });
  const [searchNewPrices, setSearchNewPrices] = useState({});

  const [isLoading, setLoadingPricing] = useState(true);
  const [isLoadingOfferCompareModal, setLoadingOfferCompareModal] = useState(false);
  const [hideInput, setHideInput] = useState({});

  const {
    greenEnergy, creditPreApproved, annualUsage, utilityIdObj,
    zipCode, requestedStartDate, annualKwh, termLength, squareFeet, monthlyUsage,
    dontKnow
  } = newCallFormData;

  useEffect(() => {
    const getPrices = async () => {
      priceActions.resetPriceMatrix();
      const pricesListData = await priceActions.getPriceListAction();
      const { priceMatrix, suppliers, termsList } = pricesListData;
      const supplyListSorted = getSupplierListSorted(suppliers);
      const dataPriceTable = getTablePricesData(termsList, supplyListSorted, priceMatrix, masterSetting, annualUsage);
      const bestOffersList = setBestOffersList(dataPriceTable, termLength, termsList);
      setSuppliersList(supplyListSorted);
      setTermListSorted(['All', ...termsList]);
      setTableOffers(dataPriceTable);
      setBannerOfferList(bestOffersList);
      setBest3OffersList(bestOffersList);
      setLoadingPricing(false);
    };

    setLoadingPricing(true);
    getPrices();
  }, [searchNewPrices]);

  useEffect(() => {
    const showAnnualInput = (annualKwh > 0 && annualKwh !== '');
    const showSquareFeetInput = (squareFeet > 0 && squareFeet !== '');
    const showMonthlySpendInput = ((monthlyUsage > 0 && monthlyUsage !== '') || (monthlyUsage === 'lessThan'));
    const showDetailsOptions = !dontKnow && !showMonthlySpendInput;
    setHideInput({ showAnnualInput, showSquareFeetInput, showMonthlySpendInput, showDetailsOptions });
  }, []);

  const handleSelectPlan = (offerData) => {
    setNewCall({
      ...newCallFormData,
      pricingId: offerData.pricingId,
      selectedSupplier: offerData.selectedSupplier,
      margin: offerData.margin,
      dealValue: offerData.dealValue,
      annualValue: offerData.annualValue,
      rate: offerData.rate,
      termLength: offerData.supplierTerm
    });
    navigate('/offer-summary');
  };

  const existsPriceMatrix = (Object.keys(tableOffers).length > 0);
  const hasSupplier = (existsPriceMatrix && suppliersListSorted.length > 0);
  const haveOffers = (priceMatrixSuccess && best3OffersList.length > 0);

  const formOnChange = (event, inputKeyName) => {
    let inputValue;
    if (event === null) {
      inputValue = '';
    } else {
      inputValue = event.target ? event.target.value : event.toDate();
    }
    if (event?.target) {
      if (event.target.type === 'checkbox') {
        inputValue = event.target.checked;
      }
    }

    setNewCall({
      ...newCallFormData,
      [inputKeyName]: inputValue
    });
  };

  const triggerShowPriceDetailsDescription = (offerPriceSelected, directAccess = false) => {
    if (offerPriceSelected) {
      setOfferDetailsDescriptionSelected({ offerSelected: { ...offerPriceSelected }, directAccess: directAccess });
    };
    setPriceDetailsDescription(!showPriceDetailsDescription);
  };

  const triggerCompareBanner = () => {
    setShowCompareBanner(!showCompareBanner);
    setBannerOfferList([...best3OffersList]);
  };
  const hideCompareModal = () => {
    setShowCompareBanner(false);
  }
  const triggerOfferCompareModal = async (bestOffer, eventReason) => {
    setLoadingOfferCompareModal(true);
    const utilityName = utilityIdObj.name;
    if (showPriceDetailsDescription) {
      if (eventReason && (eventReason === "backdropClick" && "escapeKeyDown") || bestOffer.event) triggerShowPriceDetailsDescription();
    }
    if (!openOfferCompareModal) {
      const detailsExtraInfo = await priceActions.findExtraPriceInfoAction({ bannerOfferList: best3OffersList, annualKwh, monthlyUsage, utilityName });
      if (bestOffer) {
        const bestOfferParsed = parseExtraBestOfferData(bestOffer, detailsExtraInfo, annualKwh, annualUsage);
        triggerShowPriceDetailsDescription(bestOfferParsed, true);
      }
      setDetailsExtraInformation(detailsExtraInfo);
      setLoadingOfferCompareModal(false);
    }
    setOpenOfferCompareModal(!openOfferCompareModal);
    setBannerOfferList([...bannerOfferList]);
    setLoadingOfferCompareModal(false);
  }

  const updateTableSelectors = (newBest3Offers) => {
    tableOffers.forEach((tableItem) => {
      tableItem.forEach((tableCell) => {
        if (tableCell.hasOwnProperty('bestOffer')) {
          const updatingOffer = newBest3Offers.find((item) => item.pricingId === tableCell.pricingId);
          updatingOffer !== undefined ? tableCell.bestOffer = updatingOffer.bestOffer : tableCell.bestOffer = '';
        }
      });
    });
    setTableOffers([...tableOffers]);
  }

  const updateOfferList = (offerPicked, userSelection = false) => {
    const selectedOffer = { ...offerPicked };
    selectedOffer.userSelection = userSelection;
    let newBest3Offers = [];
    if (selectedOffer.supplierPrice === '-' || !selectedOffer.supplierPrice) { return false }
    if (best3OffersList.length === 3) {
      newBest3Offers.push(selectedOffer);
    } else if (best3OffersList.length <= 2) {
      newBest3Offers = [...best3OffersList];
      newBest3Offers.push(selectedOffer);
    }
    newBest3Offers.sort((a, b) => { return a.supplierPrice - b.supplierPrice }).forEach((offer, index) => { offer.bestOffer = index + 1 });
    setBest3OffersList([...newBest3Offers]);
    updateTableSelectors(newBest3Offers);
  }

  const searchNewPricesTrigger = () => {
    setSearchNewPrices({
      ...searchNewPrices,
      search: true
    });
  };

  const compareBanner = (triggerCompareBannerCallback, triggerOfferCompareModalCallback, setBannerOfferList) => {
    const openOfferCompareModal = () => {
      triggerCompareBannerCallback();
      triggerOfferCompareModalCallback();
    };

    const removeElement = (indexElement) => {
      bannerOfferList.splice(indexElement, 1);
      setBannerOfferList([...bannerOfferList]);
    };

    const showOfferList = bannerOfferList.length > 0;
    const disabledCompare = bannerOfferList.length === 0;

    return (
      <div className="compare-banner">
        <div className="banner-container">
          <div className="banner-body">
            <Container maxWidth="lg" className='offer-container'>
              <Grid container spacing={2} className="offer-card-container">
                <Grid item xs={12} className="sectionTitleContainer d-flex d-flex-center-all banner-header"></Grid>
                {showOfferList &&
                  bannerOfferList.map((offerItem, index) => (
                    <Grid item xs={12} md={3} className="offer-card sectionTitleContainer d-flex d-flex-center-all">
                      {smallCards(offerItem, removeElement, index)}
                    </Grid>
                  ))
                }
                <Grid item xs={12} md={3} className="sectionTitleContainer d-flex d-flex-center-all">
                  <div className="banner-actions">
                    <Button size="small" onClick={triggerCompareBannerCallback}>
                      <img alt="bullerLogo" src={`${process.env.PUBLIC_URL}/images/bulletCross.png`}></img>
                      Clear All
                    </Button>
                    <ThemeProvider theme={energyBy5Theme}>
                      <Button
                        size="small" variant="contained" color="black"
                        className={"btn-eb5 white-txt" + (disabledCompare ? "disabled" : "")}
                        onClick={openOfferCompareModal}
                        disabled={disabledCompare}>
                        Compare
                      </Button>
                    </ThemeProvider>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    )
  };


  return (
    <div className="shop-now">
      <Grid item xs={12} className="sectionTitleContainer">
        <Steps position={4} total={5}></Steps>
      </Grid>
      <Grid item xs={12} className='LoadingContainer'>
        <Loading show={isLoading} message={"Getting your best electricity offers"}></Loading>
        <Backdrop sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoadingOfferCompareModal}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      <Container maxWidth="md">
        <Grid container spacing={4} className="filters-container">
          <Grid item xs={4} className="sectionTitleContainer">
            <FormControl fullWidth size="small" className="not-btn-style">
              <label className="inputLabel">Zip Code:</label>
              <TextField
                id="zipCode"
                type="number"
                value={zipCode}
                disabled="disabled"
                variant="outlined" />
            </FormControl>
          </Grid>
          <Grid item xs={4} className="sectionTitleContainer">
            <FormControl fullWidth size="small" className="not-btn-style">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <label className="inputLabel">Start Date:</label>
                <DatePicker
                  className="date-picker-input"
                  size="small"
                  inputFormat={'MM/DD/YYYY'}
                  minDate={moment()}
                  value={requestedStartDate || ''}
                  onChange={(event) => { formOnChange(event, 'requestedStartDate') }}
                  renderInput={(params) =>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size='small'
                      type='date'
                      fullWidth
                      required
                      {...params}
                    />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {hideInput.showAnnualInput &&
            <Grid item xs={4} className="sectionTitleContainer">
              <FormControl fullWidth size="small" className="not-btn-style">
                <label className="inputLabel">Annual KWh:</label>
                <TextField
                  id="kwh"
                  type="number"
                  value={annualKwh <= 0 ? '' : annualKwh}
                  placeholder={(annualKwh <= 0 || annualKwh === '') ? 'Unspecified' : annualKwh}
                  onChange={(event) => { formOnChange(event, 'annualKwh') }}
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }} />
              </FormControl>
            </Grid>
          }
          {hideInput.showSquareFeetInput &&
            <Grid item xs={4} className="sectionTitleContainer">
              <FormControl fullWidth size="small" className="not-btn-style">
                <label className="inputLabel">Square Feet:</label>
                <TextField
                  id="squareFeet"
                  type="number"
                  value={squareFeet <= 0 ? '' : squareFeet}
                  placeholder={(squareFeet <= 0 || squareFeet === '') ? 'Unspecified' : squareFeet}
                  onChange={(event) => { formOnChange(event, 'squareFeet') }}
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }} />
              </FormControl>
            </Grid>
          }
          {hideInput.showMonthlySpendInput &&
            <Grid item xs={4} className="sectionTitleContainer">
              <FormControl fullWidth size="small" className="not-btn-style">
                <label className="inputLabel">Monthly Spend:</label>
                <Select
                  className='alignLeft'
                  id="monthlySpend"
                  labelId="monthlyUsageLabel"
                  onChange={(event) => { formOnChange(event, 'monthlyUsage') }}
                  disabled={true}
                  value={monthlyUsage || 'dontKnowMonthlySpend'}>
                  <MenuItem value="dontKnowMonthlySpend" disabled>Unspecified</MenuItem>
                  <MenuItem value="lessThan"> Less than $5,000 </MenuItem>
                  <MenuItem value="moreThan"> More than $5,000 </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item xs={12} className="sectionTitleContainer d-flex">
            <Grid item xs={6} className="sectionTitleContainer d-flex">
              <FormControl fullWidth size="small" className="not-btn-style flex-center-y select-box align-center"><FormGroup>
                <FormControlLabel control={
                  <Checkbox
                    id="creditApproved"
                    checked={creditPreApproved}
                    onChange={(event) => { formOnChange(event, 'creditPreApproved') }}
                    inputProps={{ 'aria-label': 'controlled' }} />
                } label={<Box component="div" fontSize={15} fontWeight={700}>
                  Show offers with no credit check
                </Box>}
                />
              </FormGroup></FormControl>
            </Grid>
            <Grid item xs={6} className="sectionTitleContainer d-flex">
              <FormControl fullWidth size="small" className="not-btn-style flex-center-y select-box align-center"><FormGroup>
                <FormControlLabel control={
                  <Checkbox
                    id="greenEnergy"
                    checked={greenEnergy}
                    onChange={(event) => { formOnChange(event, 'greenEnergy') }}
                    inputProps={{ 'aria-label': 'controlled' }} />
                } label={<Box component="div" fontSize={15} fontWeight={700}>
                  Show Green Energy offers
                </Box>}
                />
              </FormGroup></FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer d-flex d-flex-center-all filter-actions-button">
            <Grid item xs={6} className="pt50">
              <Button variant="outlined" className="btn-eb5 width-full bordered margin-r-10" onClick={() => { navigate('/energy-consumption/') }}> Back </Button>
              <ThemeProvider theme={energyBy5Theme}>
                <Button size="small" variant="contained" color="blue" className="btn-eb5 white-txt apply-filter" onClick={() => { searchNewPricesTrigger() }}> Apply </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
          {haveOffers &&
            <Grid item xs={12} className="sectionTitleContainer d-flex d-flex-center-all">
              <span className="sub-text">These are our recommendations based on the information provided:</span>
            </Grid>
          }
        </Grid>
      </Container>
      <Container maxWidth="lg" className='offer-container'>
        <Grid container spacing={2} className="offer-card-container">
          {haveOffers && best3OffersList.map((bestOffer, indexBestOffer) => (
            <><Grid item xs={12} md={4} className="sectionTitleContainer d-flex d-flex-center-all" key={`best-offers-${indexBestOffer}`}>
              <div>{offerCard(bestOffer, indexBestOffer, triggerCompareBanner, handleSelectPlan, triggerOfferCompareModal, hideInput.showDetailsOptions)}</div>
            </Grid></>
          ))}
        </Grid>
      </Container>
      <Container maxWidth="md" className="offers-table-section">
        <Grid item xs={12} className="sectionTitleContainer d-flex d-flex-center-all">
          <span className="sub-text">Other offers can be selected below</span>
        </Grid>
        <Grid item xs={10} className="offers-table-container">
          {hasSupplier &&
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Supplier/Term</TableCell>
                    {suppliersListSorted.map(supplier =>
                      <TableCell align="center">
                        <span className="supplier-logo-wrapper">
                          {getSupplierLogo(supplier)}
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {existsPriceMatrix &&
                  <TableBody>
                    {tableOffers.map((rowItem, index) => (
                      <TableRow key={rowItem.pricingId ? `price-${index}` : `no-price-${index}`}>
                        {[...Array(suppliersListSorted.length + 1)].map((supplierRow, supplierRowIndex) => (
                          <TableCell
                            className={rowItem[supplierRowIndex]?.bestOffer ? `best-offer-table-cell-${rowItem[supplierRowIndex]?.bestOffer}` : ''}
                            align="center" onClick={() => { updateOfferList(rowItem[supplierRowIndex], true) }}>
                            {getPriceFormatted(rowItem[supplierRowIndex])}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                }
              </Table>
            </TableContainer>
          }
          {!hasSupplier &&
            <Grid item xs={12} className="table-alert d-flex d-flex-center-all">
              <span className="sub-text">No offers available for the selected conditions</span>
            </Grid>
          }
        </Grid>
      </Container>
      {showCompareBanner &&
        <div className="bottom-banner-display">
          {compareBanner(triggerCompareBanner, triggerOfferCompareModal, setBannerOfferList)}
        </div>
      }
      <Modal
        open={openOfferCompareModal}
        onClose={triggerOfferCompareModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={"offer-compare-modal-container" + (showPriceDetailsDescription ? " details-description-container budget-section" : "")}>
          <div className='offer-compare-modal-mask'>
            <div className='close-control-actions'>
              <Link onClick={() => { triggerOfferCompareModal({ event: 'clicked' }) }} underline="none">✕</Link>
            </div>
            <Container maxWidth="md" className='offer-container'>
              <Grid container spacing={2} className={"offer-card-container" + (showPriceDetailsDescription ? " big-card-eb5" : "")}>
                {!showPriceDetailsDescription ?
                  <>
                    <Grid item xs={12} md={3} className="sectionTitleContainer d-flex d-flex-center-all description-fields-section">
                      <span>Supplier</span>
                      <span>Term Length</span>
                      <span>Fixed Rate</span>
                      <span>Cancellation Allowances</span>
                      <span>Cancellation Penalties</span>
                      <span>Green Energy</span>
                      <span>Payment Terms</span>
                    </Grid>
                    {bannerOfferList.map((bestOffer, indexBestOffer) => (
                      <Grid item xs={12} md={3} className="sectionTitleContainer d-flex d-flex-center-all" key={`card-offer-${indexBestOffer}`}>
                        {offerCardModal(bestOffer, indexBestOffer, handleSelectPlan, detailsExtraInformation, annualKwh, triggerShowPriceDetailsDescription, hideInput.showDetailsOptions)}
                      </Grid>
                    ))}
                  </>
                  :
                  <>
                    <Grid item xs={12} className="card-title-container">
                      <span className={`card-title ${''}`}>Offer Details</span>
                    </Grid>
                    <Grid item xs={6} className="d-flex">
                      <div className="offer-details-logo">
                        {getSupplierLogo(offerDetailsDescriptionSelected.offerSelected.supplier)}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="offer-price d-flex">
                        <p><span className="price-value">{offerDetailsDescriptionSelected.offerSelected.supplierPrice}</span> ¢/kWh</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="d-flex d-flex-left padd-right-16">
                      <span className='utility-description'>{offerDetailsDescriptionSelected.offerSelected.supplierBio}</span>
                    </Grid>
                    <Grid item xs={12} className="d-flex d-flex-left">
                      <div className="offer-perks">
                        <ul className="offer-perks-list">
                          <li><img alt="bullerLogo" src={getMarker('fixedRate', offerDetailsDescriptionSelected.offerSelected.offerPerks)}></img>Fixed Rate</li>
                          <li><img alt="bullerLogo" src={getMarker('contractCancellationAllowances', offerDetailsDescriptionSelected.offerSelected.offerPerks)}></img>Contract Cancellation Allowances</li>
                          <li><img alt="bullerLogo" src={getMarker('contractCancellationPenalties', offerDetailsDescriptionSelected.offerSelected.offerPerks)}></img>Contract Cancellation Penalties</li>
                          <li><img alt="bullerLogo" src={getMarker('greenEnergy', offerDetailsDescriptionSelected.offerSelected.offerPerks)}></img>Green Energy</li>
                        </ul>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="d-flex padd-right-16">
                      <div className="offer-data-explanation">
                        <div className="offer-contracts-explanation">
                          <div className="contract-info">
                            <div className="contract-info-title">
                              <span>Contract Cancellation Allowances and Penalties</span>
                            </div>
                            <div className="contract-info-description">
                              <span>Be sure to review the specific contract cancellation provisions and penalties in this supplier’s electricity agreement. Reach out to us by chat or phone if you have any questions or if you need assistance with this.</span>
                            </div>
                          </div>
                        </div>
                        <div className="bottom-note">
                          <p>
                            <span className="fnt-bold">NOTE: </span>
                            Your total electricity expenses will be the sum of electricity supply costs from
                            <span className="fnt-bold"> {((offerDetailsDescriptionSelected.supplier) ? offerDetailsDescriptionSelected.supplier : "your supplier")} </span> and
                            regulated delivery charges from your local utility. The regulated charges from your
                            utility are not affected by the supply contract you choose.
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="d-flex padd-right-16">
                      <div className="tables-summary">
                        {getDetailsTableSummary(offerDetailsDescriptionSelected.offerSelected.budgetSummary.budgetTables.tableElectricitySupplyCharges)}
                      </div>
                    </Grid>
                    <Grid item xs={12} className="d-flex padd-right-16">
                      <div className="tables-summary table-long-name">
                        {getDetailsTableSummary(offerDetailsDescriptionSelected.offerSelected.budgetSummary.budgetTables.regulatedDeliveryCharges)}
                      </div>
                    </Grid>
                    <Grid item xs={12} className="d-flex padd-right-16">
                      <div className="tables-summary">
                        {getDetailsTableSummary(offerDetailsDescriptionSelected.offerSelected.budgetSummary.budgetTables.tableBudgetEstimate, 'simple')}
                      </div>
                    </Grid>
                  </>
                }
              </Grid>
            </Container>
            {showPriceDetailsDescription &&
              <div className="d-flex offer-details-actions">
                <ThemeProvider theme={energyBy5Theme}>
                  <Button
                    size="small" variant="contained" color="black"
                    className="btn-eb5 white-txt"
                    onClick={() => { handleSelectPlan(offerDetailsDescriptionSelected.offerSelected) }}>
                    Select Offer
                  </Button>
                  {!offerDetailsDescriptionSelected.directAccess &&
                    <Button
                      size="small" variant="contained" color="black"
                      className="btn-eb5 white-txt"
                      onClick={() => { triggerShowPriceDetailsDescription() }}>
                      Back
                    </Button>
                  }
                </ThemeProvider>
              </div>
            }
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default GetRates;
