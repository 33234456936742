import * as React from 'react';
import { useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import { useEffect, useState } from 'react';
import { Typography, Box, Button, TextField, Container, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLargeAction } from '../../../_actions/large.actions';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IMaskInput } from 'react-imask';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LoadingButton from '@mui/lab/LoadingButton';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});



const ShopLarge = () => {
  const largeActions = useLargeAction();
  const MAX_FILE_SIZE = 102400 * 10 * 10; //10MB
  const validFileExtensions = { image: ['jpg', 'png', 'jpeg', 'pdf'] };

  const isValidFileType = (fileName, fileType) => {
    if (fileName) {
      return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
    } else {
      return true;
    }

  }
  // form validation rules  
  const validationSchema = Yup.object().shape({
    contactFirstName: Yup.string()
      .required('First Name is required')
      .matches(/^[a-zA-Z]+$/, 'Only alphabets are allowed')
      .trim()
      .max(20, 'First Name has a maximum limit of 20 characters.'),
    contactLastName: Yup.string()
      .required('Last Name is required')
      .matches(/^[a-zA-Z]+$/, 'Only alphabets are allowed')
      .trim()
      .max(20, 'Last Name has a maximum limit of 20 characters.'),
    contactPhone: Yup.string().required('Phone Number is required').trim(),
    contactEmail: Yup.string()
      .required('Email is required')
      .trim()
      .email('Email must be a valid email')
      .max(50, 'Email has a maximum limit of 50 characters.'),
    legalEntityName: Yup.string()
      .required('Company Name is required')
      .trim()
      .max(100, 'Company Name has a maximum limit of 100 characters.'),
    serviceAddress: Yup.string()
      .trim()
      .max(255, 'Service Address has a maximum limit of 255 characters.'),
    description: Yup.string()
      .trim()
      .max(500, 'Description has a maximum limit of 500 characters.'),
    utilityInvoice: Yup.mixed()
      .test("is-valid-type", "Only valid of files type (jpg, png, jpeg, pdf)", value => isValidFileType(value[0] && value[0].name, "image"))
      .test("is-valid-size", "Max allowed size is 10MB", value => { return value[0] && (value[0]?.size) ? value[0]?.size <= MAX_FILE_SIZE : true })
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formData = watch();
  const [newCallFormData] = useRecoilState(newCallAtom);

  const onSubmit = (data) => {
    setIsSubmitting(true)
    createUser(data);
    reset({})
  }
  const createUser = (data) => {
    return largeActions.add(data)
  }

  useEffect(() => {
    reset({
      requestedStartDate: new Date(),
      utilityInvoice: []
    })
  }, []);
  return (
    <>
      <div className='large-comercial'>
        <Container maxWidth="sm">
          <Typography className='section-title' variant="h2" component="h2" align="center" sx={{ mb: 3 }}>
            Large Commercial <span style={{ color: '#0000ff' }}>Electricity</span>
          </Typography>
          <Typography variant="body1" component="p" sx={{ fontWeight: 500 }} align="center">Businesses that spend more than $5,000 per month in electricity
            often qualify for custom pricing. Suppliers make offers based on the specific meter’s usage profile and are often more competitive than generic pricing found on the website. 
            To request custom pricing, fill out the custom pricing form and we will contact you.</Typography>
          <Box
            sx={{
              maxWidth: '100%',
              display: 'flex',
              gap: '10px',
              margin: '20px auto',
            }}
          >
            <Box component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid rowSpacing={2} columnSpacing={0}>
                <Grid item container sm={12} md={12} rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6" align='center' sx={{ mb: 1, fontWeight: 700 }}>
                      Fill out our custom pricing form
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="First Name"
                      size='small'
                      {...register('contactFirstName')}
                      className={`form-control ${errors.contactFirstName ? 'is-invalid' : ''}`}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactFirstName?.message}</div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Last Name"
                      size='small'
                      {...register('contactLastName')}
                      className={`form-control ${errors.contactLastName ? 'is-invalid' : ''}`}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactLastName?.message}</div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Phone Number"
                      type="phone"
                      id="formatted-numberformat-input"
                      size='small'
                      className={`form-control ${errors.contactPhone ? 'is-invalid' : ''}`}
                      value={formData.contactPhone}
                      onChange={e => reset({ ...formData, contactPhone: e.target.value })}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                      // autoComplete="off"
                      {...register('contactPhone')}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactPhone?.message}</div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Email Address"
                      size='small'
                      name="email"
                      {...register('contactEmail')}
                      className={`form-control ${errors.contactEmail ? 'is-invalid' : ''}`}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactEmail?.message}</div>
                  </Grid>
                  <Grid item container alignItems="center" sm={12} xs={12} rowSpacing={2} columnSpacing={3}>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body1" component="p" sx={{ textAlign: { sm: 'right', xs: 'left' } }}>
                        How do you prefer we contact you?
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className='radio-groups radio-inline'>
                        <div className="form-group">
                          <input {...register('preferedContactMethod')} type="radio" id="phone" name="preferedContactMethod" value="Phone" />
                          <label for="phone">Phone</label>
                        </div>
                        <div className="form-group">
                          <input {...register('preferedContactMethod')} type="radio" id="email" name="preferedContactMethod" value="Email" />
                          <label for="email">Email</label>
                        </div>
                        <div className="form-group">
                          <input {...register('preferedContactMethod')} type="radio" id="either" name="preferedContactMethod" value="Either" />
                          <label for="either">Either</label>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Name of Your Organization"
                      size='small'
                      {...register('legalEntityName')}
                      className={`form-control ${errors.legalEntityName ? 'is-invalid' : ''}`}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.legalEntityName?.message}</div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Service Address"
                      size='small'
                      {...register('serviceAddress')}
                      className={`form-control ${errors.serviceAddress ? 'is-invalid' : ''}`}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.serviceAddress?.message}</div>
                  </Grid>
                  <Grid item sm={12} xs={12} container rowSpacing={2} columnSpacing={2}>
                    <Grid item sm={4} xs={4}>
                      <TextField
                        fullWidth
                        label="City"
                        size='small'
                        {...register('serviceCity')}
                        className={`form-control ${errors.serviceCity ? 'is-invalid' : ''}`}
                      />
                      <div className="invalid-feedback" style={{ color: "red" }}>{errors.serviceCity?.message}</div>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                      <TextField
                        fullWidth
                        label="State"
                        size='small'
                        {...register('serviceState')}
                        className={`form-control ${errors.serviceState ? 'is-invalid' : ''}`}
                      />
                      <div className="invalid-feedback" style={{ color: "red" }}>{errors.serviceState?.message}</div>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                      <TextField
                        fullWidth
                        label="Zip Code"
                        size='small'
                        {...register('serviceZIP')}
                        className={`form-control ${errors.serviceZIP ? 'is-invalid' : ''}`}
                      />
                      <div className="invalid-feedback" style={{ color: "red" }}>{errors.serviceZIP?.message}</div>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Estimated Monthly Usage (kWh) Square Footage of Building"
                      size='small'
                      {...register('monthlyUsage')}
                      className={`form-control ${errors.monthlyUsage ? 'is-invalid' : ''}`}
                    />
                    <div className="invalid-feedback" style={{ color: "red" }}>{errors.monthlyUsage?.message}</div>
                  </Grid>
                  <Grid item container sm={12} xs={12} alignItems="center" rowSpacing={2} columnSpacing={3}>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body1" component="p" sx={{ textAlign: { sm: 'right', xs: 'left' } }}>
                        Requested Start Date
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          size="small"
                          className='requested-date-btn'
                          inputFormat="MM/DD/YYYY"
                          value={formData?.requestedStartDate}
                          onChange={(e) => reset({
                            ...formData,
                            'requestedStartDate': e
                          })}
                          renderInput={(params) =>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              size='small'
                              type='date'
                              fullWidth
                              {...params}
                            />}
                        />
                      </LocalizationProvider>
                      <div className="invalid-feedback" style={{ color: "red" }}>{errors.requestedStartDate?.message}</div>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      fullWidth
                      label="How can we help?"
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      value={formData.description}
                      onChange={e => reset({ ...formData, description: e.target.value })}
                      {...register('description')}
                      className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                    />
                    <div className="invalid-feedback" style={{ color: ((500 - formData?.description?.length || 500) > 0) ? "" : "red" }}>Characters Left: {500 - formData?.description?.length || 500}  {errors.description?.message}</div>
                  </Grid>
                  <Grid item container alignItems="center" sm={12} xs={12} spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body1" component="p" sx={{ textAlign: { sm: 'right', xs: 'left' } }}>
                        Please upload a copy of a recent utility invoice
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Button fullWidth variant="outlined" color='primary' component="label" className='btn-outline-primary'>
                        <Typography className="invalid-feedback" variant="body1" component="div" sx={{ width: '115px' }}>
                          + File Upload
                        </Typography>
                        <input hidden type="file"
                          onChange={(e) => {
                            reset({
                              ...formData,
                              fileName: e.target.files[0].name,
                              utilityInvoice: e.target.files
                            });
                          }
                          }
                        />
                        {formData?.fileName &&
                          <Typography className="invalid-feedback" variant="body1" component="div" sx={{ textAlign: { sm: 'left', xs: 'left', fontSize: '12px', paddingRight: "0" } }}>
                            {formData?.fileName?.substring(0, 17)}...
                          </Typography>
                        }
                      </Button>
                      <Typography className="invalid-feedback" variant="body1" component="div" sx={{ textAlign: { sm: 'right', xs: 'left', color: 'red', fontSize: '12px', paddingRight: "108px" } }}>
                        {errors.utilityInvoice?.message}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <LoadingButton
                      type={'submit'}
                      loading={isSubmitting}
                      loadingPosition="end"
                      variant="contained"
                      className={(isSubmitting) ? "large-submit" : 'btn-primary'}
                      sx={{ mt: 3, mb: 2 }}
                      disabled={isSubmitting}
                      label={isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  )
}

export default ShopLarge;
