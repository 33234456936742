import React, { useEffect } from 'react'
import {Container} from '@mui/material';
import { useLocation,useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
const Campaign = () => {     
  const navigate = useNavigate();
  const location = useLocation(); 
  useEffect(() => {      
    const where = new URLSearchParams(location.search);
    const promo = where.get('promo');
    localStorage.removeItem("promo");
    localStorage.setItem("promo", (promo)?promo:'');
    navigate('/');
  }, [location.search])  
  return (
      <>
        <div className='large-comercial'>
            <Container maxWidth="xl">
                <Backdrop
                  sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={true}
                >
                  <CircularProgress color="inherit" />
              </Backdrop>
            </Container>
        </div>
      </>    
  );
};

export default Campaign;
