import { React, useState } from 'react';
import { Button, TextField, Grid, FormControl, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from "react-router-dom";
import '../custom.css';
import { useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import Steps from '../Components/Steps';

const PickStartDate = () => {
  const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
  const navigate = useNavigate();
  const format = 'MM/DD/YYYY'
  const minDate = (newCallFormData.serviceState == 'TX')
    ? moment()
    : ((moment().date() <= 20) ? moment().add(1, 'month') : moment().add(2, 'month').startOf('month'));
  const getZipPage = () => {
    return newCallFormData.referralCode
      ? "/enter-zip/"
      : "/small-commercial-electricity-zip-code-lookup/" + newCallFormData.referralCode
  };
  const backPath = newCallFormData.serviceState == 'TX'
    ? "/select-service/?zip=" + newCallFormData.zipCode
    : getZipPage();
  const validateDate = (startDate) => {
    const dateNow = moment();
    let flag = startDate.isBetween(
      dateNow,
      moment(dateNow, format).add(3, 'days')
    );
    if (startDate.isSame(dateNow, "day")) { flag = true }
    return flag;
  }
  const changeHandler = (e) => {
    setNewCall({
      ...newCallFormData,
      requestedStartDate: e.toDate(),
      isServiceUrgent: validateDate(e)
    })
  }
  const [dateOpen, setDateOpen] = useState(false);

  return (
    <div className="shop-now">
      <Grid item xs={12} className="sectionTitleContainer">
        <Steps position={2} total={5}></Steps>
      </Grid>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} className="sectionTitleContainer">
            <h2 className="header-title">When do you need your new electricity service to start?</h2>
          </Grid>
          <Grid item xs={12} className="sectionTitleContainer">
            <FormControl sx={{ bgcolor: '#fff' }} size="small" className='not-btn-style'>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <label className='label-date' for=":r2:" onClick={() => setDateOpen(true)}>
                  CHOOSE START DATE
                  <Tooltip placement="top" className="tooltip" title={<>
                    <label>Be sure to choose a contract start date that is after the last month of your current agreement. A failure to do this may result in penalties from your current supplier.</label>
                    <br /><br />
                    <label>If you are a 5 Digital Energy client, reach out to us by chat or phone if you are not sure when your current electricity contract expires.</label></>}>
                    <label className='tooltipIcon'>ⓘ</label>
                  </Tooltip>
                  <DatePicker
                    size="small"
                    inputFormat={format}
                    minDate={minDate}
                    className='calendar'
                    value={newCallFormData?.requestedStartDate || minDate}
                    onChange={changeHandler}
                    open={dateOpen}
                    onClose={() => setDateOpen(false)}
                    slots={{
                      textField: params => <TextField {...params} />
                    }}
                    renderInput={(params) =>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        size='small'
                        type='date'
                        fullWidth
                        required
                        {...params}
                      />}
                  />
                </label>
              </LocalizationProvider>
            </FormControl>
            {newCallFormData.isServiceUrgent ?
              <>
                <Alert severity="warning" className="mt50">
                  If you need electricity service in less than 3 business days, the only option may be a high monthly rate contract. Talk to one of our Digital Energy Advisors if you have any questions
                </Alert>
              </>
              : null
            }
          </Grid>
          <Grid item xs={6} className="alignLeft pt50">
            <Button
              onClick={() => {
                setNewCall(
                  {
                    ...newCallFormData,
                    step1: false
                  }
                )
                navigate(backPath)
              }}
              variant="outlined" className="btn-eb5 width-full bordered big"
            >Back</Button>
          </Grid>
          <Grid item xs={6} className="alignRight pt50">
            <Button
              onClick={() => navigate('/energy-consumption/')}
              variant="outlined"
              disabled={!newCallFormData?.requestedStartDate ? true : false}
              className={`btn-eb5 width-full bordered big
                ${!newCallFormData?.requestedStartDate ? "disable" : (newCallFormData.isServiceUrgent) ? "next" : "next"}`}>
              {newCallFormData.isServiceUrgent ? 'Continue' : 'Next'}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default PickStartDate;
