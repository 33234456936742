import { React, useEffect } from 'react'
import {
  Grid, Container, Button, FormControl, TextField, FormGroup,
  FormControlLabel, Checkbox, Radio, RadioGroup, Box
} from '@mui/material';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import '../custom.css';
import { useRecoilState } from 'recoil';
import { newCallAtom } from '../../../_state';
import Divider from '@mui/material/Divider';
import Steps from '../Components/Steps';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import AlertMessage from '../Components/AlertMessage';
import AddressAutocomplete from './AddressAutocomplete';
import { supplierListImages } from '../../../_common/app.constants';
import ReactInputMask from 'react-input-mask';

let serviceAccount = {
  address: '',
  state: '',
  city: '',
  zipCode: 0,
  ESID: ''
}
const billingAddressSchema = Yup.object().shape({
  address: Yup.string()
    .required("Address is required")
    .max(255, 'Address has a maximum limit of 255 characters.'),
  city: Yup.string()
    .required("City is required")
    .max(50, 'City has a maximum limit of 20 characters.'),
  state: Yup.string()
    .required("State is required")
    .max(20, 'State has a maximum limit of 20 characters.'),
  zipCode: Yup.string()
    .required("Zip code is required")
    .max(5, 'Zip has a maximum limit of 5 characters.'),
});
const serviceAddressSchema = billingAddressSchema.shape({
  ESID: Yup.string()
    .required("ESID/Account Number is required")
})
const contactInformationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .max(20, 'First Name has a maximum limit of 20 characters.'),
  lastName: Yup.string()
    .required("Last Name is required")
    .max(20, 'Last Name has a maximum limit of 20 characters.'),
  email: Yup.string()
    .email('Email must be a valid email')
    .max(50, 'Email has a maximum limit of 50 characters.')
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone Number is required"),
  legalEntityName: Yup.string()
    .required("Legal Entity Name is required")
    .max(100, 'Legal Entity has a maximum limit of 100 characters.'),
  federalTaxId: Yup.string()
    .matches(/^(\d{9}|\d{11})$/, 'Invalid Federal Tax ID ')
    .required("Federal Tax ID is required"),
  jobTitle: Yup.string(),
});
const validationSchema = Yup.object().shape({
  serviceAccountList: Yup.array().of(serviceAddressSchema),
  contactInformation: contactInformationSchema,
  billingAddress: billingAddressSchema
});

const getSupplierLogo = (supplier) => {
  if (supplierListImages[supplier] && supplierListImages[supplier]['image'] !== '') {
    return (<img className='supplier-logo' alt="Supplier logo" src={`${process.env.PUBLIC_URL}/images/client-logos/new-logos/${supplierListImages[supplier]['logoId']}_contract_logo.png`} />);
  }
  return supplier;
};
const OfferSummary = () => {
  const [newCallFormData, setNewCall] = useRecoilState(newCallAtom);
  const {
    contactInformation,
    billingAddress,
    serviceAccountList,
    billingServiceAddressCheck,
    isServiceUrgent,
    zipCode, serviceState, selectedSupplier } = newCallFormData;
  const formOptions = {
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    values: {
      contactInformation,
      billingAddress,
      serviceAccountList
    }
  };
  const { handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const authorizationMessage = `I authorize 5 Digital Energy to send me email communications that may 
    include important updates, newsletters, promotional offers and other relevant energy information.`;
  const navigate = useNavigate();
  const onChangeServiceAccount = (el, index) => {
    let newList = [...newCallFormData.serviceAccountList];
    let item = { ...newList[index] };
    item[el.target.name] = el.target.value;
    newList[index] = item;
    setNewCall({
      ...newCallFormData,
      serviceAccountList: [...newList],
      ...(billingServiceAddressCheck && index === 0) ? { billingAddress: newList[index] } : {}
    });
  }

  const inputOnChange = ({ value, id }, objName) => {
    const newObj = { ...newCallFormData }
    let infoObj = { ...newObj[objName] }
    infoObj[id] = value;
    if (objName === 'billingAddress' && billingServiceAddressCheck) {
      let accountListArr = [...newObj.serviceAccountList]
      accountListArr[0] = infoObj;
      newObj.serviceAccountList = (newObj.serviceAccountList.length > 1) ?
        accountListArr : [infoObj]
    }
    newObj[objName] = infoObj;
    setNewCall({ ...newObj })
  }

  const removeElement = (position) => {
    let newArr = [...serviceAccountList];
    newArr.splice(position, 1);
    setNewCall({
      ...newCallFormData,
      serviceAccountList: newArr
    });
  }
  const onSubmit = (values) => {
    navigate('/contract-review/')
  }
  const updateAutocompleteValues = (newServiceAddress, key) => {
    let newServiceAccountList = [...serviceAccountList];
    newServiceAccountList[key] = { ...newServiceAddress };
    setNewCall({
      ...newCallFormData,
      serviceAccountList: newServiceAccountList,
      ...(billingServiceAddressCheck && key === 0) ? { billingAddress: newServiceAccountList[key] } : {}
    });
  };
  const serviceAccountTemplate = (item, key) => {
    return (<Grid container key={`serviceAccount-Form-${key}`}>
      <Grid xs={6}>
        {key > 0 ? <h2>New Service Account #{key}</h2> : <h2>Service Account</h2>}
      </Grid>
      <Grid xs={6} className='delete-section'>
        {key ?
          <Button className='btn-delete'
            startIcon={<DeleteIcon />}
            onClick={() => removeElement(key)}
          >Delete</Button>
          : null
        }
      </Grid>
      {(billingAddress?.state === 'TX' || newCallFormData.serviceState === 'TX') ?
        <AddressAutocomplete
          name="autoCompleteaddress"
          label="Address"
          index={key}
          selectOnFocus
          value={item?.address}
          changeState={updateAutocompleteValues}
          errors={errors}
          required={true}
        />
        :
        <FormControl fullWidth className="field-container">
          <TextField
            name="address"
            label="Address"
            type="text"
            value={item?.address || ''}
            onChange={(val) => {
              onChangeServiceAccount(val, key);
            }}
            required
            variant="outlined" />
          <div className="invalid-feedback" style={{ color: "red" }}>
            {errors.serviceAccountList ? errors.serviceAccountList[key].address?.message : ''}
          </div>
        </FormControl>
      }
      <Box className="flex">
        <FormControl className="field-container">
          <TextField
            required
            name="city"
            label="City"
            type="text"
            value={item?.city || ''}
            onChange={(val) => {
              onChangeServiceAccount(val, key);
            }}
            variant="outlined" />
          <div className="invalid-feedback" style={{ color: "red" }}>
            {errors.serviceAccountList ? errors.serviceAccountList[key].city?.message : ''}
          </div>
        </FormControl>
        <FormControl className="field-container">
          <TextField
            required
            name="state"
            label="State"
            type="text"
            value={item?.state || ''}
            onChange={(val) => {
              onChangeServiceAccount(val, key);
            }}
            variant="outlined" />
          <div className="invalid-feedback" style={{ color: "red" }}>
            {errors.serviceAccountList ? errors.serviceAccountList[key].state?.message : ''}
          </div>
        </FormControl>
        <FormControl className="field-container">
          <TextField
            required
            name="zipCode"
            label="Zip Code"
            type="text"
            value={item?.zipCode || ''}
            onChange={(val) => {
              onChangeServiceAccount(val, key);
            }}
            variant="outlined" />
          <div className="invalid-feedback" style={{ color: "red" }}>
            {errors.serviceAccountList ? errors.serviceAccountList[key].zipCode?.message : ''}
          </div>
        </FormControl>
      </Box>
      <FormControl fullWidth className="field-container">
        <TextField
          name="ESID"
          label={(billingAddress?.state === 'TX' || newCallFormData.serviceState === 'TX') ?
            "ESID" : 'Account Number'}
          type="text"
          value={item?.ESID || ''}
          onChange={(val) =>
            onChangeServiceAccount(val, key)
          }
          required
          variant="outlined" />
        <div className="invalid-feedback" style={{ color: "red" }}>
          {errors.serviceAccountList ? errors.serviceAccountList[key].ESID?.message : ''}
        </div>
      </FormControl>
    </Grid>
    );
  }

  useEffect(() => {
    setNewCall({
      ...newCallFormData,
      serviceAccountList: [{ ...serviceAccount }],
      billingAddress: { ...billingAddress, zipCode, state: serviceState }
    })
  }, [])

  const addServiceAccount = () => {
    setNewCall({
      ...newCallFormData,
      serviceAccountList: [...newCallFormData.serviceAccountList, { ...serviceAccount }]
    })
  }

  return (
    <div className="shop-now offer-summary">
      <Grid item xs={12} className="sectionTitleContainer">
        <Steps position={5} total={5}></Steps>
      </Grid>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} className="sectionTitleContainer">
            <Grid item xs={12} className="alignLeft pt50">
              <Button variant="outlined" className="btn-eb5 width-full bordered" onClick={() => { navigate('/get-rates') }}> Back </Button>
              <h2>Please provide the following information</h2>
              {
                isServiceUrgent ?
                  <AlertMessage severity='warning' message="If you could wait to get energy after 3 business days
                we could help you get better offers. If you need to proceed,
                we will contact you anyways to help you get a better rate" />
                  :
                  null
              }
            </Grid>
          </Grid>
          <Grid item xs={6} className='form-container'>
            <Box className="flex">
              <FormControl className="field-container">
                <TextField
                  required
                  id="firstName"
                  label="First Name"
                  type="text"
                  value={contactInformation?.firstName || ''}
                  onChange={(el) => {
                    inputOnChange(el.target, 'contactInformation');
                  }}
                  variant="outlined" />
                <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactInformation?.firstName?.message}</div>
              </FormControl>
              <FormControl className="field-container">
                <TextField
                  required
                  id="lastName"
                  label="Last Name"
                  type="text"
                  value={contactInformation?.lastName || ''}
                  onChange={(el) => {
                    inputOnChange(el.target, 'contactInformation');
                  }}
                  variant="outlined" />
                <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactInformation?.lastName?.message}</div>
              </FormControl>
            </Box>
            <Box className="flex">
              <FormControl className="field-container">
                <TextField
                  required
                  id="email"
                  label="Email"
                  type="email"
                  value={contactInformation?.email || ''}
                  onChange={(el) => {
                    inputOnChange(el.target, 'contactInformation');
                  }}
                  variant="outlined" />
                <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactInformation?.email?.message}</div>
              </FormControl>
              <FormControl className="field-container">
                <ReactInputMask
                  mask="(999)-999-9999"
                  maskChar=' '
                  alwaysShowMask={false}
                  value={contactInformation?.phoneNumber || ''}
                  onChange={(el) => {
                    inputOnChange(el.target, 'contactInformation');
                  }}
                >
                  {(inputProps) => {
                    return (<TextField
                      {...inputProps}
                      required
                      id="phoneNumber"
                      label="Phone Number"
                      type="tel"
                      variant="outlined" />)
                  }}
                </ReactInputMask>
                <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactInformation?.phoneNumber?.message}</div>
              </FormControl>
            </Box>
            <FormControl fullWidth className="field-container">
              <TextField
                id="jobTitle"
                label="Job Title"
                type="text"
                value={contactInformation?.jobTitle || ''}
                onChange={(el) => {
                  inputOnChange(el.target, 'contactInformation');
                }}
                variant="outlined" />
              <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactInformation?.jobTitle?.message}</div>
            </FormControl>
            <FormControl fullWidth className="field-container">
              <TextField
                required
                id="legalEntityName"
                label="Legal Entity Name"
                type="text"
                value={contactInformation?.legalEntityName || ''}
                onChange={(el) => {
                  inputOnChange(el.target, 'contactInformation');
                }}
                variant="outlined" />
              <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactInformation?.legalEntityName?.message}</div>
            </FormControl>
            <FormControl fullWidth className="field-container">
              <TextField
                required
                id="federalTaxId"
                label="Federal Tax ID"
                type="text"
                value={contactInformation?.federalTaxId || ''}
                onChange={(el) => {
                  inputOnChange(el.target, 'contactInformation');
                }}
                variant="outlined" />
              <div className="invalid-feedback" style={{ color: "red" }}>{errors.contactInformation?.federalTaxId?.message}</div>
            </FormControl>
            <FormGroup margin="dense" className='authorization-check'>
              <FormControlLabel control={<Checkbox defaultChecked />} label={authorizationMessage} />
            </FormGroup>
            <FormGroup margin="dense" className='billing-address'>
              <FormControlLabel
                onChange={(el) => {
                  const serviceAddressArr = [...serviceAccountList]
                  let newBillingObj = { ...serviceAddressArr[0] };
                  setNewCall({
                    ...newCallFormData,
                    billingServiceAddressCheck: el.target.checked,
                    billingAddress: newBillingObj
                  });
                }}
                control={<Checkbox checked={billingServiceAddressCheck} />}
                label="Billing address is the same as Service location." />
            </FormGroup>
            {serviceAccountList.map((item, index) => {
              return serviceAccountTemplate(item, index);
            })}
            <Button className='btn-normal'
              startIcon={<AddCircleIcon />}
              onClick={addServiceAccount}
            >
              Add Service Account
            </Button>
            <div className='section-gap-30' />
            <h2>Billing Address</h2>
            <FormControl fullWidth className='field-container'>
              <TextField
                required
                id="address"
                label="Address"
                type="text"
                value={billingAddress?.address || ''}
                disabled={(billingServiceAddressCheck) ? true : false}
                onChange={(el) => {
                  inputOnChange(el.target, 'billingAddress');
                }}
                variant="outlined" />
              <div className="invalid-feedback" style={{ color: "red" }}>{errors.billingAddress?.address?.message}</div>
            </FormControl>
            <Box className="flex">
              <FormControl className='field-container'>
                <TextField
                  required
                  id="city"
                  label="City"
                  type="text"
                  value={billingAddress?.city || ''}
                  onChange={(el) => {
                    inputOnChange(el.target, 'billingAddress');
                  }}
                  disabled={(billingServiceAddressCheck) ? true : false}
                  variant="outlined" />
                <div className="invalid-feedback" style={{ color: "red" }}>{errors.billingAddress?.city?.message}</div>
              </FormControl>
              <FormControl className='field-container'>
                <TextField
                  required
                  id="state"
                  label="State"
                  type="text"
                  value={billingAddress?.state || ''}
                  disabled
                  onChange={(el) => {
                    inputOnChange(el.target, 'billingAddress');
                  }}
                  variant="outlined" />
                <div className="invalid-feedback" style={{ color: "red" }}>{errors.billingAddress?.state?.message}</div>
              </FormControl>
              <FormControl className='field-container'>
                <TextField
                  required
                  id="zipCode"
                  label="Zip Code"
                  type="text"
                  value={billingAddress?.zipCode || ''}
                  disabled
                  onChange={(el) => {
                    inputOnChange(el.target, 'billingAddress');
                  }}
                  variant="outlined" />
                <div className="invalid-feedback" style={{ color: "red" }}>{errors.billingAddress?.zipCode?.message}</div>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} className='grid-information'>
            <div className='information-container summary-box bg-gray'>
              <Grid item xs={12} className='info-header'>
                {getSupplierLogo(selectedSupplier.name)}
                <h2>Energy Offer Summary</h2>
                <div className='section-gap-30' />
              </Grid>
              <Grid container className=''>
                <Grid item xs={6} >
                  <h4>Contact Information</h4>
                  <p>
                    {contactInformation?.lastName || 'Last Name'}, {contactInformation?.firstName || 'Name'}
                  </p>
                  <p>
                    {contactInformation?.email || 'Email'}
                  </p>
                  <p>
                    {contactInformation?.phoneNumber || 'Phone Number'}
                  </p>
                  <p>
                    {contactInformation?.legalEntityName || 'Legal Entity Name'}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <h4>Mailing Address</h4>
                  <p>
                    {billingAddress?.address || 'Address'}
                  </p>
                  <p>
                    {billingAddress?.city || 'City'}, {billingAddress?.state || 'State'}, {billingAddress?.zipCode || 'zip code'}
                  </p>
                </Grid>
                <Grid item xs={6} margin="dense">
                  <p className="custom-align">
                    <span className='left'>Rate:</span> <span>{newCallFormData?.rate}</span>
                  </p>
                  <p className="custom-align">
                    <span className='left'>Term Length:</span><span>{newCallFormData?.termLength}</span>
                  </p>
                </Grid>
                <Grid item xs={6} margin="dense">
                  <p className="custom-align">
                    <span className='left'>Start Date: </span> <span>{moment(newCallFormData.requestedStartDate).format('MM-DD-YYYY')} </span>
                  </p>
                  <p className="custom-align">
                    <span className='left'>Utility: </span> <span>{newCallFormData?.utilityIdObj?.name || 'Utility'} </span>
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      value={newCallFormData?.creditPreApproved ? 'creditPreApproved' : false}
                    >
                      <FormControlLabel
                        disabled
                        className='radio-label'
                        name="creditPreApproved"
                        value="creditPreApproved"
                        control={<Radio />}
                        label={<Typography variant="body1">Credit Pre-approved</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                  <label className='splitter'>|</label>
                  <FormControl>
                    <RadioGroup
                      disabled
                      value={newCallFormData?.greenEnergy ? 'greenEnergy' : false}
                    >
                      <FormControlLabel
                        disabled
                        className='radio-label'
                        name="greenEnergy"
                        value="greenEnergy"
                        control={<Radio />}
                        label={<Typography variant="body1">Green energy</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className='section-gap-20' />
                  <Divider orientation='horizontal' />
                  {serviceAccountList.map((item, key) => {
                    return (<div key={`serviceAccountInfo-${key}`}>
                      <h4>Service Account {key > 0 ? `#${key}` : ''}</h4>
                      <p>{item.address}</p>
                      <p>{item.city}, {item.state}</p>
                      <p>{item.zipCode}</p>
                    </div>)
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Divider orientation='horizontal' />
                  <div className='section-gap-20' />
                  <h2>Renewing Your Contract</h2>
                  <p>Electricity markets are volatile and prices change everyday. We believe that clients should always be shopping and considering renewal offers.</p>
                  <p>You can expect to hear from us with advice when favorable purchasing opportunities exist in advance of your next renewal.</p>
                  <p>We will also share  important energy news that is important for your business through our newsletter, "The 5 by 5” and periodic market letters from our Chief Strategy Officer.</p>
                </Grid>
              </Grid>
            </div>
            <AlertMessage severity='warning'
              message="Suppliers always reserve the right to reject a contract that is not signed in the same business day.">
            </AlertMessage>
            <Button className='btn-eb5 alignRight'
              onClick={handleSubmit(onSubmit)}>
              Review Contract
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default OfferSummary;
